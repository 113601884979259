import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MensajeConfirmacion } from '../../models';
import { DialogoConfirmacionComponent } from './confirmacion.component';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ConfirmacionService {

  private config: MatDialogConfig = {
    disableClose: false
  };

  constructor(public dialog: MatDialog) { }

  confirmar(mensaje: MensajeConfirmacion): Observable<boolean> {

    this.config.data = mensaje;
    this.config.closeOnNavigation = true;
    this.config.disableClose = true;
    this.config.hasBackdrop = true;
    const dialogRef = this.dialog.open(DialogoConfirmacionComponent, this.config);

    return dialogRef.afterClosed().pipe(
      map(resultado => String(resultado) === 'OK'),
      catchError(error => {
        console.log(error);
        return of<boolean>(false);
      })
    );
  }

  confirmarSimple(msj: string): Observable<boolean> {
    const confirma = { mensaje: msj, ocultaCancel: false, tipo: 'question' } as MensajeConfirmacion;
    return this.confirmar(confirma);
  }

}
