import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { DialogoCargaComponent } from './dialogo-carga.component';

@Injectable()
export class CargandoService {


    private config: MatDialogConfig = {
        disableClose: true,
        hasBackdrop: true,
        closeOnNavigation: true,
        panelClass: 'load-dialog-container'
    };

    private dialogRef: MatDialogRef<DialogoCargaComponent>;

    constructor(public dialog: MatDialog) { }

    current = false;

    toggle(cargando: boolean) {
        if (this.current !== cargando) {
            if (cargando && (this.dialogRef === null || typeof this.dialogRef === 'undefined')) {
                setTimeout(() => {
                    this.dialogRef = this.dialog.open(DialogoCargaComponent, this.config);
                }, 0);
            } else {
                setTimeout(() => {
                    if (this.dialogRef !== null && !cargando && typeof this.dialogRef !== 'undefined') {
                        this.dialogRef.close('cierra');
                        this.dialogRef = null;
                    }
                }, 0);
            }
            this.current = cargando;
        }
    }

}
