import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'int-vacio',
  template: ``,
  styles: []
})
export class VacioComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {}

}
