import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable()
export class PortalToHomeService {

    private subject = new Subject<any>();

    constructor() { }

    Poll(): void {
        this.subject.next({ now: new Date()});
    }

    Check(): Observable<any> {
        return this.subject.asObservable();
    }

}
