import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'int-selector-menu',
    templateUrl: 'dlg-selector-menu.component.html',
    styleUrls: ['dlg-selector-menu.component.css'],
})
export class DialogSelectorInvMenuComponent implements OnInit {

    muestraInventario = false;
    menuSelected: any;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private dialogRef: MatDialogRef<DialogSelectorInvMenuComponent>) {

    }

    ngOnInit(): void {
        this.menuSelected = this.data.menuSelected;
    }

    cancelar(): void {
        this.dialogRef.close();
    }

    onSelect(event: any): void {
        this.menuSelected = event;
        this.muestraInventario = true;
        this.dialogRef.close({ menuSelected: this.menuSelected, muestraInventario: this.muestraInventario });
    }
    /*
    aceptar(): void {
        this.dialogRef.close({ });
    }*/

}
