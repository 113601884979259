import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PedidosService } from '../pedidos.service';
import { CargandoService, MensajesService } from 'src/app/shared';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
    selector: 'int-dlg-pedido-historal',
    templateUrl: 'detalle-historial.component.html',
    styleUrls: ['detalle-historial.component.css'],
})
export class DlgDetPedidoHistorialComponent implements OnInit {

    detalleHist: any[];
    tokenId: string;
    cliente: string;

    columnDisplay: string[] = ['num', 'suc', 'tipo', 'carton', 'calibre', 'ancho', 'largo', 'bob', 'req', 'aprox', 'cant'];
    dataSource: MatTableDataSource<any[]>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private pedidoSrv: PedidosService,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private dialogRef: MatDialogRef<DlgDetPedidoHistorialComponent>) {

    }

    ngOnInit(): void {
        this.tokenId = this.data.tokenId;
        this.cliente = this.data.cliente;
        this.detalleHist = [];
        this.getHistorial();
    }

    aceptar(): void {
        this.dialogRef.close();
    }

    private getHistorial(): void {
        this.load.toggle(true);
        this.pedidoSrv.dameDetPedidosHistorial(this.cliente, this.tokenId).subscribe(
            resHist => {
                // console.log(JSON.stringify(resHist));
                this.dataSource = new MatTableDataSource(resHist);
                this.dataSource.paginator = this.paginator;
                this.load.toggle(false);
            },
            errHist => {
                this.load.toggle(false);
                console.error('Error al obtener el detalle', errHist);
                this.msjSrv.agregaError('Error al obtener el detalle, intenta más tarde.');
            }
        );
    }

}
