import { Idle } from '@ng-idle/core';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajesService, CargandoService, LoginService, Auth2Service, HttpErrorsService } from './../../shared';
import { RegistroService } from './registro.service';

@Component({
    selector: 'int-registro',
    templateUrl: './registro.component.html',
    styles: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

    registroForm: FormGroup;
    oculta = true;
    estados: any[];

    constructor(private router: Router,
                private msjSrv: MensajesService,
                private loadSrv: CargandoService,
                private builder: FormBuilder,
                private regServ: RegistroService,
                private title: Title
                ) {
        this.title.setTitle('Registro de Clientes');
    }

    ngOnInit() {
        this.llenaEstados();
        this.armaForm();
    }

    private armaForm(): void {
        this.registroForm = this.builder.group({
            tipoPersona: ['moral', Validators.required],
            companyName: [null, null],
            rfc: [null, [Validators.required,
                // tslint:disable-next-line: max-line-length
                Validators.pattern('^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$')]],

            direccion: [null, [Validators.required]],
            ciudad: [null, [Validators.required]],
            estado: [null, [Validators.required]],
            cp: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
            telefono: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],

            nombre1: [null, null],
            email1: [null, null],
            cargo1: [null, null],

            nombre2: [null, null],
            email2: [null, null],
            cargo2: [null, null],

            nombre: [null, null],
            apellido: [null, null],
            email: [null, null],
        });

        this.cambiaTipo(null);
    }

    cambiaTipo(event: any): void {
        if (this.registroForm.value.tipoPersona === 'moral') {
            // Moral si va
            this.registroForm.controls.companyName.setValue(null);
            this.registroForm.controls.companyName.setValidators([Validators.required]);
            this.registroForm.controls.companyName.updateValueAndValidity();

            this.registroForm.controls.nombre1.setValue(null);
            this.registroForm.controls.nombre1.setValidators([Validators.required]);
            this.registroForm.controls.nombre1.updateValueAndValidity();

            this.registroForm.controls.email1.setValue(null);
            this.registroForm.controls.email1.setValidators([Validators.required, Validators.email]);
            this.registroForm.controls.email1.updateValueAndValidity();

            this.registroForm.controls.cargo1.setValue(null);
            this.registroForm.controls.cargo1.setValidators([Validators.required]);
            this.registroForm.controls.cargo1.updateValueAndValidity();

            this.registroForm.controls.nombre2.setValue(null);
            this.registroForm.controls.nombre2.setValidators([Validators.required]);
            this.registroForm.controls.nombre2.updateValueAndValidity();

            this.registroForm.controls.email2.setValue(null);
            this.registroForm.controls.email2.setValidators([Validators.required, Validators.email]);
            this.registroForm.controls.email2.updateValueAndValidity();

            this.registroForm.controls.cargo2.setValue(null);
            this.registroForm.controls.cargo2.setValidators([Validators.required]);
            this.registroForm.controls.cargo2.updateValueAndValidity();

            // Fisica no va
            this.registroForm.controls.nombre.setValue(null);
            this.registroForm.controls.nombre.setValidators([Validators.nullValidator]);
            this.registroForm.controls.nombre.updateValueAndValidity();

            this.registroForm.controls.apellido.setValue(null);
            this.registroForm.controls.apellido.setValidators([Validators.nullValidator]);
            this.registroForm.controls.apellido.updateValueAndValidity();

            this.registroForm.controls.email.setValue(null);
            this.registroForm.controls.email.setValidators([Validators.nullValidator]);
            this.registroForm.controls.email.updateValueAndValidity();

            this.registroForm.updateValueAndValidity();
        } else {
            // Fisica si va
            this.registroForm.controls.nombre.setValue(null);
            this.registroForm.controls.nombre.setValidators([Validators.required]);
            this.registroForm.controls.nombre.updateValueAndValidity();

            this.registroForm.controls.apellido.setValue(null);
            this.registroForm.controls.apellido.setValidators([Validators.required]);
            this.registroForm.controls.apellido.updateValueAndValidity();

            this.registroForm.controls.email.setValue(null);
            this.registroForm.controls.email.setValidators([Validators.required, Validators.email]);
            this.registroForm.controls.email.updateValueAndValidity();
            // Moral no va
            this.registroForm.controls.companyName.setValue(null);
            this.registroForm.controls.companyName.setValidators([Validators.nullValidator]);
            this.registroForm.controls.companyName.updateValueAndValidity();

            this.registroForm.controls.nombre1.setValue(null);
            this.registroForm.controls.nombre1.setValidators([Validators.nullValidator]);
            this.registroForm.controls.nombre1.updateValueAndValidity();

            this.registroForm.controls.email1.setValue(null);
            this.registroForm.controls.email1.setValidators([Validators.nullValidator]);
            this.registroForm.controls.email1.updateValueAndValidity();

            this.registroForm.controls.cargo1.setValue(null);
            this.registroForm.controls.cargo1.setValidators([Validators.nullValidator]);
            this.registroForm.controls.cargo1.updateValueAndValidity();

            this.registroForm.controls.nombre2.setValue(null);
            this.registroForm.controls.nombre2.setValidators([Validators.nullValidator]);
            this.registroForm.controls.nombre2.updateValueAndValidity();

            this.registroForm.controls.email2.setValue(null);
            this.registroForm.controls.email2.setValidators([Validators.nullValidator]);
            this.registroForm.controls.email2.updateValueAndValidity();

            this.registroForm.controls.cargo2.setValue(null);
            this.registroForm.controls.cargo2.setValidators([Validators.nullValidator]);
            this.registroForm.controls.cargo2.updateValueAndValidity();

            this.registroForm.updateValueAndValidity();
        }
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    irLogin(): void {
        this.navegar('login');
    }

    registrarUser(): void {
        this.loadSrv.toggle(true);
        this.regServ.createUser(this.registroForm.getRawValue()).subscribe(
            resCreate => {
                if (resCreate.code === 0) {
                    this.msjSrv.agregaInfo(resCreate.message);
                    this.irLogin();
                } else {
                    this.msjSrv.agregaError(resCreate.message);
                }
                this.loadSrv.toggle(false);
            },
            errCreate => {
                console.error('Error al crear el usuario...', errCreate);
                this.msjSrv.agregaError('Error al crear la petición de registro, intente más tarde.');
                this.loadSrv.toggle(false);
            }
        );
    }

    private llenaEstados(): void {
        this.estados = [];
        this.estados.push({value: null, viewValue: 'Seleccione...'});
        this.estados.push({value: 'AGUASCALIENTES', viewValue: 'AGUASCALIENTES'});
        this.estados.push({value: 'BAJA CALIFORNIA', viewValue: 'BAJA CALIFORNIA'});
        this.estados.push({value: 'BAJA CALIFORNIA SUR', viewValue: 'BAJA CALIFORNIA SUR'});
        this.estados.push({value: 'CAMPECHE', viewValue: 'CAMPECHE'});
        this.estados.push({value: 'CHIAPAS', viewValue: 'CHIAPAS'});
        this.estados.push({value: 'CHIHUAHUA', viewValue: 'CHIHUAHUA'});
        this.estados.push({value: 'COAHUILA', viewValue: 'COAHUILA'});
        this.estados.push({value: 'COLIMA', viewValue: 'COLIMA'});
        this.estados.push({value: 'DISTRITO FEDERAL', viewValue: 'DISTRITO FEDERAL'});
        this.estados.push({value: 'DURANGO', viewValue: 'DURANGO'});
        this.estados.push({value: 'GUANAJUATO', viewValue: 'GUANAJUATO'});
        this.estados.push({value: 'GUERRERO', viewValue: 'GUERRERO'});
        this.estados.push({value: 'HIDALGO', viewValue: 'HIDALGO'});
        this.estados.push({value: 'JALISCO', viewValue: 'JALISCO'});
        this.estados.push({value: 'MEXICO', viewValue: 'MÉXICO'});
        this.estados.push({value: 'MICHOACAN', viewValue: 'MICHOACAN'});
        this.estados.push({value: 'MORELOS', viewValue: 'MORELOS'});
        this.estados.push({value: 'NAYARIT', viewValue: 'NAYARIT'});
        this.estados.push({value: 'NUEVO LEON', viewValue: 'NUEVO LEÓN'});
        this.estados.push({value: 'OAXACA', viewValue: 'OAXACA'});
        this.estados.push({value: 'PUEBLA', viewValue: 'PUEBLA'});
        this.estados.push({value: 'QUERETARO', viewValue: 'QUERÉTARO'});
        this.estados.push({value: 'QUINTANA ROO', viewValue: 'QUINTANA ROO'});
        this.estados.push({value: 'SAN LUIS POTOSI', viewValue: 'SAN LUIS POTOSÍ'});
        this.estados.push({value: 'SINALOA', viewValue: 'SINALOA'});
        this.estados.push({value: 'SONORA', viewValue: 'SONORA'});
        this.estados.push({value: 'TABASCO', viewValue: 'TABASCO'});
        this.estados.push({value: 'TAMAULIPAS', viewValue: 'TAMAULIPAS'});
        this.estados.push({value: 'TLAXCALA', viewValue: 'TLAXCALA'});
        this.estados.push({value: 'VERACRUZ', viewValue: 'VERACRUZ'});
        this.estados.push({value: 'YUCATAN', viewValue: 'YUCATÁN'});
        this.estados.push({value: 'ZACATECAS', viewValue: 'ZACATECAS'});
    }

}
