import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { MatDialog } from '@angular/material';
import { InventariosApartadosService } from './inventario-apartado.service';
import { DialogSelectorInvMenuComponent } from '../inventarios';

@Component({
    selector: 'int-inventarios-apartado',
    templateUrl: './inventario-apartado.component.html',
    styleUrls: ['./inventario-apartado.component.css']
})
export class InventariosApartadosComponent implements OnInit {

    muestraInventario = false;
    menuSelected: any;

    constructor(private router: Router,
                private config: PortalConfigService,
                private invApartSrv: InventariosApartadosService,
                private dialog: MatDialog,
                private load: CargandoService,
                private msjSrv: MensajesService) {
        this.config.app = 'Inventario Especial';
    }

    ngOnInit() {
        this.menuSelected = null;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    onSelect(event: any): void {
        this.menuSelected = event;
        this.muestraInventario = true;
    }

    openSelectorMenu(): void {
        this.dialog.open(DialogSelectorInvMenuComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                menuSelected: this.menuSelected
            }
        }).afterClosed().subscribe(resClose => {
            if (resClose) {
                this.menuSelected = resClose.menuSelected;
                this.muestraInventario = resClose.muestraInventario;
            }
        });
    }
}
