import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService, precisionRound } from 'src/app/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CalculosService } from './calculos.service';
import { MatDialog } from '@angular/material';
import { DialogResultComponent } from '../dialog-result';


@Component({
    selector: 'int-calculos',
    templateUrl: './calculos.component.html',
    styleUrls: ['./calculos.component.css']
})
export class CalculosComponent implements OnInit {

    material: any[] = [];
    calibre: any[] = [];
    calculoForm: FormGroup;
    tituloCalculo: string;
    tipo: string;
    label1: string;
    label2: string;
    label3: string;
    ocultaInput: boolean;
    ocultaCombo: boolean;
    icono: string;

    constructor(private router: Router,
                private actRoute: ActivatedRoute,
                private dialog: MatDialog,
                private builder: FormBuilder,
                private config: PortalConfigService,
                private calSrv: CalculosService,
                private load: CargandoService,
                private msjSrv: MensajesService) {
        this.actRoute.params.subscribe(p => {
            if (p.tipo) {
                this.tipo = p.tipo;
                // console.log('Tipo... ', this.tipo);
                this.confForm();
            } else {
                this.navegar('/calculadoras');
            }
        });
    }

    private confForm(): void {
        this.calculoForm = this.builder.group({
            material: [null, null],
            calibre: [null, null],
            valor1: [null, null],
            valor2: [null, null],
            valor3: [null, null]
        });
        this.ocultaInput = false;
        this.ocultaCombo = false;
        switch (this.tipo) {
            case '1':
                this.tituloCalculo = 'De hojas a kilos';
                this.label1 = 'Ancho Cm.';
                this.label2 = 'Largo Cm.';
                this.label3 = 'No. Hojas';
                this.icono = 'fullscreen';
                this.calculoForm.controls.material.setValidators([Validators.required]);
                this.calculoForm.controls.calibre.setValidators([Validators.required]);
                this.calculoForm.controls.valor1.setValidators([Validators.required, Validators.min(50), Validators.max(110)]);
                this.calculoForm.controls.valor2.setValidators([Validators.required, Validators.min(55), Validators.max(180)]);
                this.calculoForm.controls.valor3.setValidators([Validators.required]);
                break;
            case '2':
                this.tituloCalculo = 'De kilos a hojas';
                this.label1 = 'Ancho Cm.';
                this.label2 = 'Largo Cm.';
                this.label3 = 'No. Kilos';
                this.icono = 'fullscreen_exit';
                this.calculoForm.controls.material.setValidators([Validators.required]);
                this.calculoForm.controls.calibre.setValidators([Validators.required]);
                this.calculoForm.controls.valor1.setValidators([Validators.required, Validators.min(50), Validators.max(110)]);
                this.calculoForm.controls.valor2.setValidators([Validators.required, Validators.min(55), Validators.max(180)]);
                this.calculoForm.controls.valor3.setValidators([Validators.required]);
                break;
            case '3':
                this.tituloCalculo = 'De hojas a m2';
                this.label1 = 'Ancho Cm.';
                this.label2 = 'Largo Cm.';
                this.label3 = 'No. Hojas';
                this.icono = 'unfold_less';
                this.calculoForm.controls.material.setValidators([Validators.nullValidator]);
                this.calculoForm.controls.calibre.setValidators([Validators.nullValidator]);
                this.calculoForm.controls.valor1.setValidators([Validators.required, Validators.min(50), Validators.max(110)]);
                this.calculoForm.controls.valor2.setValidators([Validators.required, Validators.min(55), Validators.max(180)]);
                this.calculoForm.controls.valor3.setValidators([Validators.required]);
                this.ocultaCombo = true;
                break;
            case '4':
                this.tituloCalculo = 'Precio x hoja';
                this.label1 = 'Ancho Cm.';
                this.label2 = 'Largo Cm.';
                this.label3 = 'Precio Kg.';
                this.icono = 'unfold_more';
                this.calculoForm.controls.material.setValidators([Validators.required]);
                this.calculoForm.controls.calibre.setValidators([Validators.required]);
                this.calculoForm.controls.valor1.setValidators([Validators.required, Validators.min(50), Validators.max(110)]);
                this.calculoForm.controls.valor2.setValidators([Validators.required, Validators.min(55), Validators.max(180)]);
                this.calculoForm.controls.valor3.setValidators([Validators.required]);
                break;
            case '5':
                this.tituloCalculo = 'Precio x millar';
                this.label1 = 'Ancho Cm.';
                this.label2 = 'Largo Cm.';
                this.label3 = 'Precio Kg.';
                this.icono = 'show_chart';
                this.calculoForm.controls.material.setValidators([Validators.required]);
                this.calculoForm.controls.calibre.setValidators([Validators.required]);
                this.calculoForm.controls.valor1.setValidators([Validators.required, Validators.min(50), Validators.max(110)]);
                this.calculoForm.controls.valor2.setValidators([Validators.required, Validators.min(55), Validators.max(180)]);
                this.calculoForm.controls.valor3.setValidators([Validators.required]);
                break;
            case '6':
                this.tituloCalculo = 'Precio x m2';
                this.label1 = 'Precio Kg.';
                this.label2 = '';
                this.label3 = '';
                this.icono = 'center_focus_weak';
                this.calculoForm.controls.material.setValidators([Validators.required]);
                this.calculoForm.controls.calibre.setValidators([Validators.required]);
                this.calculoForm.controls.valor1.setValidators([Validators.required]);
                this.calculoForm.controls.valor2.setValidators([Validators.nullValidator]);
                this.calculoForm.controls.valor3.setValidators([Validators.nullValidator]);
                this.ocultaInput = true;
                break;
            default :
                this.navegar('/calculadoras');
                break;
        }
        this.config.app = this.tituloCalculo;
        this.getMaterial();
    }

    ngOnInit() {
    }

    regresar(): void {
        this.navegar('/calculadoras');
    }

    calcular(): void {
        // console.log(JSON.stringify(this.calculoForm.getRawValue()));
        let operacion = 0;
        let texto = 'Sin resultado';
        switch (this.tipo) {
            case '1':
                operacion = ((Number(this.calculoForm.getRawValue().valor1) / 100) *
                                   (Number(this.calculoForm.getRawValue().valor2) / 100) *
                                   (Number(this.calculoForm.getRawValue().calibre) / 1000)) *
                                   (Number(this.calculoForm.getRawValue().valor3));
                texto = this.format(Math.round(operacion)) + ' Kilos.';
                break;
            case '2':
                operacion = Number(this.calculoForm.getRawValue().valor3) / (
                    (Number(this.calculoForm.getRawValue().valor1) / 100) *
                    (Number(this.calculoForm.getRawValue().valor2) / 100) *
                    (Number(this.calculoForm.getRawValue().calibre) / 1000));
                texto =  this.format(Math.round(operacion)) + ' Hojas.';
                break;
            case '3':
                operacion = (
                        (Number(this.calculoForm.getRawValue().valor1) / 100) *
                        (Number(this.calculoForm.getRawValue().valor2) / 100)
                    ) * Number(this.calculoForm.getRawValue().valor3);
                texto =  this.format(Math.round(operacion)) + ' m2.';
                break;
            case '4':
                operacion = (
                    (Number(this.calculoForm.getRawValue().valor1) / 100) *
                    (Number(this.calculoForm.getRawValue().valor2) / 100) *
                    (Number(this.calculoForm.getRawValue().calibre) / 1000)) * Number(this.calculoForm.getRawValue().valor3);
                texto =  this.format(Math.round(operacion)) + ' por hoja.';
                break;
            case '5':
                operacion = (
                    (Number(this.calculoForm.getRawValue().valor1) / 100) *
                    (Number(this.calculoForm.getRawValue().valor2) / 100) *
                    (Number(this.calculoForm.getRawValue().valor3) * 1000) *
                    (Number(this.calculoForm.getRawValue().calibre) / 1000));
                texto =  this.format(Math.round(operacion)) + ' por millar.';
                break;
            case '6':
                operacion = Number(this.calculoForm.getRawValue().valor1) * (Number(this.calculoForm.getRawValue().calibre) / 1000);
                texto =  this.format(Math.round(operacion)) + ' por m2.';
                break;
            default :
                this.navegar('/calculadoras');
                break;
        }
        this.dialog.open(DialogResultComponent, {
            width: '50%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                resultado: texto
            }
        });
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    private getMaterial(): void {
        this.load.toggle(true);
        this.calSrv.getMaterial().subscribe(
            resMat => {
                this.material = resMat;
                this.load.toggle(false);
            },
            errMat => {
                console.error('Error al consultar el material::: ', errMat);
                this.msjSrv.agregaError('Error al consultar los materiales...');
                this.load.toggle(false);
            }
        );
    }

    buscaCalibre($event): void {
        this.load.toggle(true);
        this.calibre = [];
        this.calSrv.getCalibres(this.calculoForm.value.material).subscribe(
            resCal => {
                this.calibre = resCal;
                this.load.toggle(false);
            },
            errCal => {
                console.error('Error al consultar los Calibres::: ', errCal);
                this.msjSrv.agregaError('Error al consultar los calibres...');
                this.load.toggle(false);
            }
        );
    }

    private format(valor: number): any {
        return (valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

}
