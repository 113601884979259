import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PortalConfigService {

    private isDarkTheme = new BehaviorSubject<boolean>(false);
    isDarkTheme$ = this.isDarkTheme.asObservable();
    private appName = new BehaviorSubject<string>('Inventarios');
    appName$ = this.appName.asObservable();

    constructor() {
    }

    set isDark(isDark: boolean) {
        this.isDarkTheme.next(isDark);
    }

    set app(app: string) {
        this.appName.next(app);
    }

}
