import { Injectable } from '@angular/core';

@Injectable()
export class IntAppsService {

    private app: Aplicacion[] = [
        { Id: 1, Nombre: 'PortalP', cveAplicacion: 'PE', Descripcion: 'Portal para Pedidos' },
    ];

    get aplicaciones(): Aplicacion[] {
        return this.app;
    }
}

export interface Aplicacion {
    Id: number;
    Nombre: string;
    cveAplicacion?: string;
    Descripcion: string;
    IdRol?: number;
}