import { Idle } from '@ng-idle/core';
import { Component, Inject, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajesService, CargandoService, LoginService, Auth2Service, HttpErrorsService } from './../shared';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
    selector: 'int-login',
    templateUrl: './login.component.html',
    styles: []
})
export class LoginComponent implements AfterViewInit {

    loginForm: FormGroup;
    oculta = true;
    shortcuts: ShortcutInput[] = [];

    constructor(private router: Router,
                private msjSrv: MensajesService,
                private loadSrv: CargandoService,
                private accessSrv: LoginService,
                private builder: FormBuilder,
                private title: Title,
                private authSrv: Auth2Service,
                private httpErroSrv: HttpErrorsService,
                @Inject('IDLE_TIMEOUT') private timeout: number,
                private idle: Idle,
                // private deviceService: DeviceDetectorService
                ) {
        this.title.setTitle('Acceso Inventarios');
        this.loginForm = this.builder.group({
            usuario: ['', Validators.required],
            accesskey: ['', Validators.required]
        });
    }

    ngAfterViewInit(): void {
        this.shortcuts.push({
            key: 'ctrl + alt + shift + m',
            preventDefault: true,
            command: e => this.navegar('login-admin')
        });
    }

    onSubmit() {
        this.loadSrv.toggle(true);
        this.accessSrv.login(this.loginForm.value.usuario, this.loginForm.value.accesskey, 'desktop', false).toPromise().then(
            usuario => {
                if (usuario.code === 0) {
                    this.authSrv.login(usuario);
                    this.loadSrv.toggle(false);
                    if (this.idle.isRunning) {
                        this.idle.interrupt();
                    }
                    this.idle.watch();
                    if (this.authSrv.redirectUrl && this.authSrv.redirectUrl.length > 0) {
                        this.router.navigateByUrl(this.authSrv.redirectUrl);
                        this.authSrv.redirectUrl = null;
                    } else {
                        this.router.navigate(['/']);
                    }
                } else {
                    this.loadSrv.toggle(false);
                    this.msjSrv.agregaError(usuario.message);
                }
            }).catch(error => {
                this.loadSrv.toggle(false);
                this.msjSrv.agregaError(this.httpErroSrv.getError('login', +error.status));
            });
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    irRegistrar(): void {
        this.navegar('/registro');
    }

}
