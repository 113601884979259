import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

  private _map: { [key: string]: any };

  constructor() {
    this._map = {};
  }

  public get sessionMap(): { [key: string]: any } {
    return this._map;
  }

}
