import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bsize'
})
export class BsizePipe implements PipeTransform {

  transform(value: any, precision: number = 0): string {
    const nValue = isNaN(+value) ? 0 : +value;
    if (nValue <= 999) {
      return `${nValue} bytes`;
    } else if (nValue <= 999999) {
      return `${precisionRound(nValue / 1000, 2)} Kb`;
    } else if (nValue > (999999)) {
      return `${precisionRound(nValue / 1000000, 2)} Mb`;
    }


    return null;
  }

}
export function precisionRound(value: number, precision: number = 0): number {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
}
