import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from 'src/app/shared';

@Injectable()
export class RecoveryPasswordService {

    private relPath = {
        SolicitarClaveRecuperacionURL: '/services/recovery/SolicitarClaveRecuperacion.php',
        ReenvioClaveRecuperacionURL: '/services/recovery/ReenvioClaveRecuperacion.php',
        CambioPasswordURL: '/services/recovery/CambioPassword.php'
    };

    constructor(@Inject('AUTH_API_ENDPOINT') private api: string,
                private http: HttpClient,
                private storeSrv: StoreManagement) { }

    SolicitarClaveRecuperacion(user: string, correo: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
        const url = `${this.api}${this.relPath.SolicitarClaveRecuperacionURL}`;
        const param = { recovery: { usuario: user, email: correo } };
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('Articulos', null)),
            );
    }

    ReenvioClaveRecuperacion(user: string, correo: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
        const url = `${this.api}${this.relPath.ReenvioClaveRecuperacionURL}`;
        const param = { recovery: { usuario: user, email: correo } };
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('Articulos', null)),
            );
    }

    CambioPassword(user: string, correo: string, cveRecuperacion: string, pass: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
        const url = `${this.api}${this.relPath.CambioPasswordURL}`;
        const param = { recovery: { usuario: user, email: correo, claveRecuperacion: cveRecuperacion, password: pass } };
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('Articulos', null)),
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
