import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { SolicitudesService } from './solicitudes.service';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DlgDetSolicitudComponent } from './dlg-det-solicitud';

@Component({
    selector: 'int-solicitudes-ctes',
    templateUrl: './solicitudes.component.html',
    styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {

    displayedColumns = ['Folio', 'ID', 'Usuario', 'Nombre', 'Company', 'Estatus'];
    solicitudes: any[] = [];

    dataSource = new MatTableDataSource<any>();
    solicitudSelected: any = null;
    regActivo = false;

    @ViewChild(MatPaginator, null) paginator: MatPaginator;

    constructor(private router: Router,
                private config: PortalConfigService,
                private solSrv: SolicitudesService,
                private load: CargandoService,
                private dialog: MatDialog,
                private msjSrv: MensajesService) {
        this.config.app = 'Solicitudes de Clientes';
    }

    ngOnInit() {
        this.solicitudes = null;
        this.getSolicitudes();
    }

    private getSolicitudes(): void {
        this.load.toggle(true);
        this.solSrv.getSolicitudes().subscribe(
            resSol => {
                this.solicitudes = resSol;
                this.dataSource = new MatTableDataSource<any>(this.solicitudes);
                this.dataSource.paginator = this.paginator;
                this.regActivo = false;
                this.load.toggle(false);
            },
            errSol => {
                this.load.toggle(false);
                console.error('Error al consultar las Solicitudes::: ', errSol);
                this.msjSrv.agregaError('Error al obtener las solicitudes, intente más tarde.');
            }
        );
    }

    seleccionaElemento(elementSelected: any): void {
        this.solicitudSelected = elementSelected;
        this.regActivo = true;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    verDetalle(): void {
        const dialogRef = this.dialog.open(DlgDetSolicitudComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                solicitudSelected: this.solicitudSelected,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

}
