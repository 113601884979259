import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CargandoService, MensajesService, ConfirmacionService, SessionService, StoreManagement } from 'src/app/shared';
import { SelectorUsuariosService } from './selector-usuarios.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
    selector: 'int-dlg-selector-usuarios',
    templateUrl: 'selector-usuarios.component.html',
    styleUrls: ['selector-usuarios.component.css'],
})
export class SelectorUsuariosComponent implements OnInit {

    displayedColumns = ['Usuario', 'Nombre', 'Company'];
    clientes: any[];
    dataSource = new MatTableDataSource<any>();
    clienteSelected: any = null;

    @ViewChild(MatPaginator, null) paginator: MatPaginator;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private ssnServ: SessionService,
                private snnStorage: StoreManagement,
                private srvUser: SelectorUsuariosService,
                private dialogRef: MatDialogRef<SelectorUsuariosComponent>) {
    }

    ngOnInit(): void {
        this.getClientes();
    }

    getClientes(): void {
        this.load.toggle(true);
        this.clientes = [];
        this.srvUser.getClientesVendedor(this.ssnServ.principal.id).subscribe(
            resCte => {
                this.clientes = resCte;
                this.dataSource = new MatTableDataSource<any>(this.clientes);
                this.dataSource.paginator = this.paginator;
                this.load.toggle(false);
            },
            err => {
                console.error('Error al obtener los cliente:', err);
                this.msjSrv.agregaError('Error al obtener los usuarios, intente nuevamente');
                this.load.toggle(false);
            }
        );
    }

    seleccionaElemento(elementSelected: any): void {
        this.clienteSelected = elementSelected;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    cancelar() {
        this.dialogRef.close(false);
    }

    aceptar() {
        this.snnStorage.savePerfilUsuario(this.clienteSelected);
        this.dialogRef.close(true);
    }

}
