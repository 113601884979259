import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IntSharedModule, MaterialIntModule, MatPaginatorIntlEs, OnlyNumber, DecimalField} from './../shared';
import { PortalRoutingModule } from './portal.routing';
import { PortalComponent } from './portal.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material';

import { VacioComponent, InicioComponent, CalculadoraMenuComponent,
         InventariosComponent, InventariosService, CalculosComponent,
         CalculosService, DialogResultComponent, PDFVisorComponent,
         EspecificacionesComponent, ComparativosComponent, DetInventarioComponent,
         PedidosComponent, PedidosService, DlgDetPedidoHistorialComponent,
         DlgAddPedidoComponent, PedidosPendietesComponent, NotificacionesService,
         PreferenciasComponent, PreferenciasService, MenuInventarioComponent,
         DialogSelectorInvMenuComponent, DlgEnviarPedidoComponent,
         SelectorUsuariosComponent, SelectorUsuariosService,
         InventariosApartadosComponent, InventariosApartadosService,
         MenuAppService, PortalToHomeService, HomeToPortalService,
         DocumentosService } from './componentes';

import { SolicitudesComponent, SolicitudesService, DlgDetSolicitudComponent,
         VisitasComponent, VisitasService, AdministradoresComponent, AdministradoresService,
         DlgMergeAdminComponent, ReportePedidosComponent, RepPedidosService,
         ClientesComponent, ClientesService, DlgDetClienteComponent } from './componentes/admin';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        IntSharedModule,
        MaterialIntModule,
        PortalRoutingModule
    ],
    declarations: [OnlyNumber, DecimalField,
        PortalComponent, VacioComponent, InicioComponent, CalculadoraMenuComponent,
        InventariosComponent, CalculosComponent, DialogResultComponent,
        PDFVisorComponent, EspecificacionesComponent, ComparativosComponent,
        DetInventarioComponent, PedidosComponent, DlgDetPedidoHistorialComponent,
        DlgAddPedidoComponent, PedidosPendietesComponent, SolicitudesComponent,
        DlgDetSolicitudComponent, VisitasComponent, AdministradoresComponent,
        DlgMergeAdminComponent, ReportePedidosComponent, ClientesComponent,
        DlgDetClienteComponent, PreferenciasComponent, MenuInventarioComponent,
        DialogSelectorInvMenuComponent, DlgEnviarPedidoComponent, SelectorUsuariosComponent,
        InventariosApartadosComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        DatePipe, InventariosService, CalculosService, PedidosService,
        SolicitudesService, VisitasService, AdministradoresService,
        RepPedidosService, ClientesService, NotificacionesService,
        PreferenciasService, SelectorUsuariosService, InventariosApartadosService,
        MenuAppService, PortalToHomeService, HomeToPortalService,
        DocumentosService,
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs }
    ],
    entryComponents: [
        DialogResultComponent, PDFVisorComponent, DlgDetPedidoHistorialComponent,
        DlgAddPedidoComponent, DlgDetSolicitudComponent, DlgMergeAdminComponent,
        DlgDetClienteComponent, DialogSelectorInvMenuComponent, DlgEnviarPedidoComponent,
        SelectorUsuariosComponent
    ]
})
export class PortalModule { }
