import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService,
    CargandoService, SessionService, StoreManagement,
    ConfirmacionService } from 'src/app/shared';
import { InventariosService } from './../inventarios.service';
import { MatDialog } from '@angular/material';
import { DlgAddPedidoComponent } from '../add-pedido';
import { PDFVisorComponent } from '../../pdf-visor';
import { DialogSelectorInvMenuComponent } from '../dlg-selector-menu';
import { InventariosApartadosService } from '../../inventario-apartado/inventario-apartado.service';

@Component({
    selector: 'int-det-inventario',
    templateUrl: './det-inventario.component.html',
    styleUrls: ['./det-inventario.component.css']
})
export class DetInventarioComponent implements OnChanges, OnInit {

    @Input() menuSelected: any;
    @Input() EsApartado: boolean;
    inventario: any[] = [];

    displayedColumns: string[] = [];
    groupDisplayColumns: string[] = [];
    groupSeparadorColumns: string[] = [];
    ELEMENT_DATA: any[];
    dataSource: any[];

    rowColor: string;
    rowColorTotal: string;
    textColor: string;
    fechaSistema = new Date();

    muestraDetalle = false;

    esMobile = false;

    idUsuario: number;
    username: string;
    cliente: string;
    aplicaPerfil: boolean;

    constructor(private router: Router,
                private config: PortalConfigService,
                private invSrv: InventariosService,
                private invApartadoSrv: InventariosApartadosService,
                private load: CargandoService,
                private dlgSrv: ConfirmacionService,
                private srvSession: SessionService,
                private dialog: MatDialog,
                private ssnStora: StoreManagement,
                private msjSrv: MensajesService) {
        this.esMobile = this.srvSession.principal.device === 'desktop' ? false : true;
    }

    ngOnChanges(changes: SimpleChanges) {
        const tmp: SimpleChange = changes.menuSelected;
        if (tmp.previousValue !== undefined) {
            if (tmp.previousValue !== tmp.currentValue) {
                this.ngOnInit();
            }
        }
    }

    ngOnInit() {
        this.muestraDetalle = false;
        this.getUsuarioPerfil();
        if (this.EsApartado) {
            if (this.menuSelected.tarima === '0') {
                this.generaEncabezado();
                this.getInventarioApartado();
            } else {
                this.inventario = [];
                this.muestraDetalle = true;
            }
        } else {
            if (this.menuSelected.tarima === '0') {
                this.generaEncabezado();
                this.getInventario();
            } else {
                this.generaEncabezadoTarima();
                this.getInventarioTarima();
            }
        }
    }

    private getUsuarioPerfil() {
        let usuarioPerfil: any = this.ssnStora.getPerfilUsuario();
        if (usuarioPerfil !== 'undefined') {
          usuarioPerfil = JSON.parse(usuarioPerfil);
          this.cliente = usuarioPerfil.id_intelisis;
          this.username = usuarioPerfil.username;
          this.idUsuario = usuarioPerfil.id;
          this.aplicaPerfil = true;
        } else {
          this.cliente = this.srvSession.principal.idIntelisis;
          this.username = this.srvSession.principal.username;
          this.idUsuario = this.srvSession.principal.id;
          this.aplicaPerfil = false;
        }
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    private getInventarioApartado(): void {
        this.load.toggle(true);
        this.invApartadoSrv.dameInventarioApartado(this.menuSelected.clave, this.cliente).subscribe(
            resInv => {
                this.inventario = resInv;
                // console.log(JSON.stringify(this.inventario));
                if (this.inventario.length > 0) {
                    this.generaGrupos();
                    this.clasificaColor();
                    this.muestraDetalle = true;
                } else {
                    this.muestraDetalle = true;
                }
                this.load.toggle(false);
            },
            errInv => {
                console.error('Error al obetenr el detalle del inventario', errInv);
                this.msjSrv.agregaError('Error al consultar el inventario, intente más tarde.');
                this.load.toggle(false);
            },
        );
    }

    private getInventario(): void {
        this.load.toggle(true);
        this.invSrv.dameDetalleInventario(this.menuSelected.clave, this.menuSelected.almacen).subscribe(
            resInv => {
                this.inventario = resInv;
                // console.log(JSON.stringify(this.inventario));
                if (this.inventario.length > 0) {
                    this.generaGrupos();
                    this.clasificaColor();
                    this.muestraDetalle = true;
                } else {
                    this.muestraDetalle = true;
                }
                this.load.toggle(false);
            },
            errInv => {
                console.error('Error al obetenr el detalle del inventario', errInv);
                this.msjSrv.agregaError('Error al consultar el inventario, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    private getInventarioTarima(): void {
        this.load.toggle(true);
        this.invSrv.dameDetalleInventarioTarima(this.menuSelected.tipo).subscribe(
            resT => {
                this.inventario = resT;
                // console.log(JSON.stringify(this.inventario));
                if  (this.inventario.length > 0) {
                    this.generaGruposTarima();
                    this.rowColor = 'rowTarima';
                    this.rowColorTotal = 'rowTotalTarima';
                    this.muestraDetalle = true;
                } else {
                    this.muestraDetalle = true;
                }
                this.load.toggle(false);
            },
            errT => {
                console.error('Error al obetenr el detalle del inventario Tarima', errT);
                this.msjSrv.agregaError('Error al consultar el inventario, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    private generaEncabezado(): void {
        this.displayedColumns = [];
        this.groupDisplayColumns = [];
        this.groupSeparadorColumns = [];

        this.displayedColumns.push('calibre');
        this.groupDisplayColumns.push('1');
        this.groupSeparadorColumns.push('s1');

        this.displayedColumns.push('ancho');
        this.groupDisplayColumns.push('2');
        this.groupSeparadorColumns.push('s2');

        this.displayedColumns.push('m2Kg');
        this.groupDisplayColumns.push('3');
        this.groupSeparadorColumns.push('s3');

        if (this.EsApartado) {
            this.displayedColumns.push('fechaApartado');
            this.groupDisplayColumns.push('7');
            this.groupSeparadorColumns.push('s7');
        } else {
            if (this.menuSelected.clave === '390') {
                this.displayedColumns.push('observaciones');
                this.groupDisplayColumns.push('4');
                this.groupSeparadorColumns.push('s4');
            }

            if (this.menuSelected.almacen === '2') {
                this.displayedColumns.push('llegada');
                this.groupDisplayColumns.push('5');
                this.groupSeparadorColumns.push('s5');
            }
        }
        this.displayedColumns.push('accion');
        this.groupDisplayColumns.push('6');
        this.groupSeparadorColumns.push('s6');
    }

    private generaEncabezadoTarima(): void {
        this.displayedColumns = [];
        this.groupDisplayColumns = [];
        this.groupSeparadorColumns = [];

        this.displayedColumns.push('calibreT');
        this.groupDisplayColumns.push('t1');
        this.groupSeparadorColumns.push('st1');

        this.displayedColumns.push('medidasT');
        this.groupDisplayColumns.push('t2');
        // this.groupSeparadorColumns.push('st2');

        this.displayedColumns.push('hojasT');
        this.groupDisplayColumns.push('t3');
        // this.groupSeparadorColumns.push('st3');

        this.displayedColumns.push('pesoT');
        this.groupDisplayColumns.push('t4');
        // this.groupSeparadorColumns.push('st4');

        this.displayedColumns.push('tarimaT');
        this.groupDisplayColumns.push('t5');
        // this.groupSeparadorColumns.push('st5');

        this.displayedColumns.push('accionT');
        this.groupDisplayColumns.push('t6');
    }

    private generaGrupos(): void {
        this.ELEMENT_DATA = [];
        const calibre = Array.from(new Set(this.inventario.map(item => item.calibre)));
        let summaryGroup = 0;
        calibre.forEach(e => {
            this.ELEMENT_DATA.push({group: e});
            summaryGroup = 0;
            this.inventario.forEach(i => {
                if (i.calibre === e) {
                    this.ELEMENT_DATA.push(i);
                    summaryGroup = summaryGroup + Number(i.peso);
                }
            });
            this.ELEMENT_DATA.push({groupTotal: e, groupTotalPeso: summaryGroup});
        });
        this.dataSource = this.ELEMENT_DATA;
    }

    private generaGruposTarima(): void {
        this.ELEMENT_DATA = [];
        const cartonId = Array.from(new Set(this.inventario.map(item => item.carton_id)));
        let summaryPeso = 0;
        let summaryTarimas = 0;
        cartonId.forEach(e => {
            this.ELEMENT_DATA.push({group: e});
            summaryPeso = 0;
            summaryTarimas = 0;
            this.inventario.forEach(i => {
                if (i.carton_id === e) {
                    this.ELEMENT_DATA.push(i);
                    summaryPeso = summaryPeso + Number(i.peso);
                    summaryTarimas = summaryTarimas + Number(i.n_tarimas);
                }
            });
            this.ELEMENT_DATA.push({ groupTotal: e,
                                     groupTotalPeso: summaryPeso,
                                     groupTotalTarimas: summaryTarimas});
        });
        // console.log(this.ELEMENT_DATA);
        this.dataSource = this.ELEMENT_DATA;
    }

    isGroup(index, item): boolean {
        return item.group;
    }

    isGroupSumary(index, item): boolean {
        return item.groupTotal;
    }

    getGranTotal(): number {
        let granTotal = 0;
        this.inventario.forEach(i => {
            granTotal = granTotal + Number(i.peso);
        });
        return granTotal;
    }

    getGranTotalTarimas(column: string): number {
        let granTotal = 0;
        if (column === 'peso') {
            this.inventario.forEach(i => {
                granTotal = granTotal + Number(i.peso);
            });
        } else {
            this.inventario.forEach(i => {
                granTotal = granTotal + Number(i.n_tarimas);
            });
        }
        return granTotal;
    }

    MandaImprimir(): void {
        // console.log(this.menuSelected);
        if (this.menuSelected.tarima === '1') {
            this.load.toggle(true);
            this.invSrv.imprimeTarima(this.menuSelected.tipo).subscribe(
                resTarima => {
                    // this.extractContent(resTarima, 'Inventario_Tarima_' + this.menuSelected.tipo + '.pdf');
                    this.dialog.open(PDFVisorComponent, {
                        width: '90%',
                        closeOnNavigation: true,
                        disableClose: true,
                        hasBackdrop: true,
                        data: {
                            info: this.menuSelected.descripcion,
                            esData: true,
                            nameFile: 'Inventario_Tarima_' + this.menuSelected.tipo + '.pdf',
                            url: resTarima
                        }
                    });
                    this.load.toggle(false);
                },
                errTarima => {
                    console.error('Error al bajar el inventario...', errTarima);
                    this.msjSrv.agregaError('Error al bajar el inventario, intente nuevamente');
                }
            );
        } else {
            this.load.toggle(true);
            const esApartadoParam = (this.EsApartado ? 'V' : 'F');
            const cteParam = (this.EsApartado ? this.cliente : 'NA');
            this.invSrv.imprimeBobina(this.menuSelected.almacen,
                                      this.menuSelected.clave,
                                      this.menuSelected.id, cteParam, esApartadoParam).subscribe(
                resBobina => {
                    this.load.toggle(false);
                    this.dialog.open(PDFVisorComponent, {
                        width: '90%',
                        closeOnNavigation: true,
                        disableClose: true,
                        hasBackdrop: true,
                        data: {
                            info: this.menuSelected.descripcion,
                            esData: true,
                            nameFile: 'Inventario_Bobina_' + this.menuSelected.clave + '.pdf',
                            url: resBobina
                        }
                    });
                },
                errBobina => {
                    console.error('Error al bajar el inventario...', errBobina);
                    this.msjSrv.agregaError('Error al bajar el inventario, intente nuevamente');
                }
            );
        }
    }

    private clasificaColor(): void {
        // SBS Ultra
        if (this.menuSelected.clave === '30') {
            this.rowColor = 'rowCarton7';
            this.rowColorTotal = 'rowTotal7';
            this.textColor = '';
            return;
        }

        // Metsa
        if (this.menuSelected.clave === '39' ||
            this.menuSelected.clave === '74' ||
            this.menuSelected.clave === '54' ||
            this.menuSelected.clave === '76' ||
            this.menuSelected.clave === '52' ||
            this.menuSelected.clave === '57' ||
            this.menuSelected.clave === '51') {
            this.rowColor = 'rowCarton8';
            this.rowColorTotal = 'rowTotal8';
            this.textColor = '';
            return;
        }

        // CKB
        if (this.menuSelected.clave === '10') {
            this.rowColor = 'rowCarton4';
            this.rowColorTotal = 'rowTotal4';
            this.textColor = '';
            return;
        }

        // Kraft Back
        if (this.menuSelected.clave === '75') {
            this.rowColor = 'rowCarton9';
            this.rowColorTotal = 'rowTotal9';
            this.textColor = '';
            return;
        }

        // Reverso y Euro
        if (this.menuSelected.clave === '19' ||
            this.menuSelected.clave === '65' ||
            this.menuSelected.clave === '15') {
            this.rowColor = 'rowCarton2';
            this.rowColorTotal = 'rowTotal2';
            return;
        }
        this.rowColor = 'rowCarton5';
        this.rowColorTotal = 'rowTotal5';
        this.textColor = '';
    }

    openAddPedido(itemInventario: any): void {
        this.dialog.open(DlgAddPedidoComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                inventario: itemInventario,
                menuSelected: this.menuSelected
            }
        });
    }

    openSelectorMenu(): void {
        this.dialog.open(DialogSelectorInvMenuComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                menuSelected: this.menuSelected
            }
        }).afterClosed().subscribe(resClose => {
            if (resClose) {
                this.menuSelected = resClose.menuSelected;
                this.ngOnInit();
            }
        });
    }

    private insert_pedido(item: any): void {
        this.load.toggle(true);
        const params = {
            token: this.srvSession.principal.tokenPedido,
            cliente: this.username,
            Calidad: item.Calidad,
            calibre: item.calibre,
            Ancho: item.Ancho,
            Largo: item.Largo,
            hojas: item.hojas,
            peso: item.peso,
            n_tarimas: item.n_tarimas,
            carton_id: item.carton_id,
            medida: item.medida,
            Almacen: item.Almacen,
            Articulo: item.Articulo,
        };
        this.invSrv.insertaPedidoTarima(params).subscribe(
            resInsert => {
                if (resInsert.code === 0) {
                    this.msjSrv.agregaOK(resInsert.message);
                } else {
                    this.msjSrv.agregaError(resInsert.message);
                }
                this.load.toggle(false);
            },
            errInsert => {
                console.error('Error al insertar la partida:::', errInsert);
                this.msjSrv.agregaError('Error al insertar la partida, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    agregaTarimaPartida(item: any): void {
        let mensageAdvertencia = 'Se agregará ' + item.n_tarimas + ' tarima(s) ['
                                        + item.carton_id + ' de calibre '
                                        + item.calibre + ' con medidas de '
                                        + item.medida + ' que contiene '
                                        + item.hojas + ' hojas y '
                                        + item.peso + ' de peso] al pedido. ¿Desea continuar?';
        if (this.aplicaPerfil) {
            mensageAdvertencia = 'Se agregará ' + item.n_tarimas + ' tarima(s) ['
                + item.carton_id + ' de calibre '
                + item.calibre + ' con medidas de '
                + item.medida + ' que contiene '
                + item.hojas + ' hojas y '
                + item.peso + ' de peso]  para el usuario [' + this.username + ']. ¿Desea continuar?';
        }
        this.dlgSrv.confirmarSimple(mensageAdvertencia).subscribe(
            res => {
                if (res) {
                    this.insert_pedido(item);
                }
            }
        );
    }

}
