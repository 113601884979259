import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { InventariosService } from './inventarios.service';
import { MatDialog } from '@angular/material';
import { DialogSelectorInvMenuComponent } from './dlg-selector-menu';

@Component({
    selector: 'int-inventarios',
    templateUrl: './inventarios.component.html',
    styleUrls: ['./inventarios.component.css']
})
export class InventariosComponent implements OnInit {

    muestraInventario = false;
    menuSelected: any;

    constructor(private router: Router,
                private config: PortalConfigService,
                private dialog: MatDialog) {
        this.config.app = 'Inventarios';
    }

    ngOnInit() {
        this.menuSelected = null;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    onSelect(event: any): void {
        this.menuSelected = event;
        this.muestraInventario = true;
    }

    openSelectorMenu(): void {
        this.dialog.open(DialogSelectorInvMenuComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                menuSelected: this.menuSelected
            }
        }).afterClosed().subscribe(resClose => {
            if (resClose) {
                this.menuSelected = resClose.menuSelected;
                this.muestraInventario = resClose.muestraInventario;
            }
        });
    }

}
