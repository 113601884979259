import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Auth2Service } from './auth2.service';
import { SessionService } from './session.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private auth2Service: Auth2Service,
                private ssnSrv: SessionService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return Promise.resolve(this.auth2Service.isLoggedOn())
            .then(isloggedOn => {
                if (isloggedOn) {
                    // console.log('AuthGuard::Con logueo....');
                    return true;
                } else {
                    // console.log('AuthGuard::Sin logueo....');
                    this.auth2Service.redirectUrl = '/';
                    this.router.navigate(['login']);
                    return false;
                }
            });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

}
