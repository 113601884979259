import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'int-dialogo-carga',
    template: `
    <mat-dialog-content style="overflow: visible">
        <mat-spinner></mat-spinner>
    </mat-dialog-content>
  `,
    styles: [``]
})
export class DialogoCargaComponent {

    constructor(public dialogRef: MatDialogRef<DialogoCargaComponent>) { }

}
