import { Injectable } from '@angular/core';
import { StoreManagement } from '../utils/store-management';
import { SessionService } from './session.service';
import { LoginService } from './login.service';
import { Identity } from '../models/identity';

@Injectable()
export class Auth2Service {

    redirectUrl: string;

    constructor(private ssnSrv: SessionService,
                private loginSrv: LoginService,
                private storeSrv: StoreManagement) {
        // console.log('Auth2Service:constructor');
    }

    isLoggedOn(): boolean | Promise<boolean> {
        // console.log('Auth2Service:isLoggedOn');
        if (this.ssnSrv.isLoggedOn) {
            if (this.ssnSrv.hasToken) {
                if (this.ssnSrv.hasUser) {
                    return true;
                } else {
                    return Promise.resolve(this.loadUser());
                }
            } else {
                return Promise.resolve(this.recoverToken())
                    .then(recovered => {
                        if (recovered) {
                            return Promise.resolve(this.ssnSrv.hasUser || this.loadUser());
                        } else {
                            return recovered;
                        }
                    })
                    .catch(error => {
                        console.log('Auth2Service:Error:recoverToken', error);
                        return false;
                    });
            }
        } else {
            return Promise.resolve(this.recoverToken())
                .then(recovered => {
                    if (recovered) {
                        return Promise.resolve(this.loadUser());
                    } else {
                        return recovered;
                    }
                })
                .catch(error => {
                    console.log('Auth2Service:Error:recoverToken', error);
                    return false;
                });
        }
    }

    loadUser(): boolean | Promise<boolean> {
        // console.log('Auth2Service:loadUser');

        if (this.ssnSrv.hasToken) {
            return Promise.resolve(this.ssnSrv.hasUser || this.loginSrv.perfil(this.ssnSrv.token)
                .toPromise()
                .then(principal => {
                    // principal.admin = (principal.rol.toUpperCase() === 'ADMINISTRADOR');
                    // console.log('Auth2Service:loadUser:principal', principal);
                    this.ssnSrv.principal = principal;
                    this.ssnSrv.hasUser = true;
                    // console.log('Auth2Service:loadUser:principal.admin', principal.admin);
                    // this.ssnSrv.isAdmin = principal.admin;
                    return true;
                }).catch(error => {
                    console.log('Error:loadUser', error);
                    return false;
                }));
        } else {
            return Promise.resolve(this.recoverToken())
                .then(recovered => {
                    if (recovered) {
                        return this.loadUser();
                    } else {
                        return recovered;
                    }
                })
                .catch(error => {
                    console.log('Auth2Service:Error:recoverToken', error);
                    return false;
                });
        }
    }

    recoverToken(): boolean | Promise<boolean> {
        // console.log('Auth2Service:recoverToken');
        const savedToken = this.storeSrv.getToken();
        if (savedToken !== 'undefined' && savedToken !== null) {
            this.ssnSrv.token = savedToken;
            this.ssnSrv.hasToken = true;
            return this.isTokenValid();
        } else {
            return false;
        }
    }

    isTokenValid(): Promise<boolean> {
        // console.log('Auth2Service:isTokenValid');
        if (this.ssnSrv.hasToken) {
            return Promise.resolve(this.loginSrv.ping(this.ssnSrv.token)
                .toPromise()
                .then(valid => {
                    return valid;
                }).catch(error => {
                    console.log('Auth2Service:Error:isTokenValid', error);
                    this.ssnSrv.token = null;
                    this.ssnSrv.hasToken = false;
                    this.storeSrv.removeToken();
                    return false;
                }));
        } else {
            return Promise.resolve(this.recoverToken())
                .then(recovered => {
                    if (recovered) {
                        return true;
                    }
                    return recovered;
                })
                .catch(error => {
                    console.log('Auth2Service:Error:recoverToken', error);
                    return false;
                });
        }
    }

    idAdmin(): boolean | Promise<boolean> {
        return Promise.resolve(this.isLoggedOn())
            .then(is => {
                if (is) {
                    return this.ssnSrv.isAdmin;
                } else {
                    return false;
                }
            });
    }

    login(identity: Identity): void {
        this.ssnSrv.isLoggedOn = true;
        this.storeSrv.saveToken(identity.tokenId);
        this.ssnSrv.token = identity.tokenId;
        this.ssnSrv.hasToken = true;
        // TODO traer usuario
        // identity.admin = (identity.rol.toUpperCase() === 'ADMINISTRADOR');
        this.ssnSrv.principal = identity;
        this.ssnSrv.hasUser = true;
        // this.ssnSrv.isAdmin = identity.admin;
    }

    logout() {
        this.ssnSrv.isLoggedOn = false;
        this.storeSrv.removeToken();
        this.ssnSrv.token = null;
        this.ssnSrv.hasToken = false;
        this.ssnSrv.principal = null;
        this.ssnSrv.hasUser = false;
        this.ssnSrv.isAdmin = false;
    }

    /*
    authHeaders(headers: Headers, accept = 'application/json'): Headers {
        if (!headers || headers === null) {
            headers = new Headers();
        }
        if (this.ssnSrv.hasToken) {
            headers.append('Authorization', `Bearer ${this.ssnSrv.token}`);
            if (accept !== null) {
                headers.append('Accept', accept);
            }
        }
        return headers;
    }*/

}
