import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PortalComponent } from './portal.component';

import { AuthGuard, AuthGuardAdmin, AuthGuardCliente } from '../shared';
import { InicioComponent, CalculadoraMenuComponent, InventariosComponent, CalculosComponent,
         EspecificacionesComponent, ComparativosComponent, PedidosComponent, PreferenciasComponent,
         InventariosApartadosComponent } from './componentes';
import { SolicitudesComponent, VisitasComponent, AdministradoresComponent,
         ReportePedidosComponent, ClientesComponent } from './componentes/admin';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PortalComponent,
    children: [
      { path: '',                       component: InicioComponent,               canActivate: [AuthGuard] },
      { path: 'calculadoras',           component: CalculadoraMenuComponent,      canActivate: [AuthGuardCliente] },
      { path: 'inventarios',            component: InventariosComponent,          canActivate: [AuthGuardCliente] },
      { path: 'calculo/:tipo',          component: CalculosComponent,             canActivate: [AuthGuardCliente] },
      { path: 'especificaciones',       component: EspecificacionesComponent,     canActivate: [AuthGuardCliente] },
      { path: 'comparativos',           component: ComparativosComponent,         canActivate: [AuthGuardCliente] },
      { path: 'pedidos',                component: PedidosComponent,              canActivate: [AuthGuardCliente] },
      { path: 'inventarios-apartado',   component: InventariosApartadosComponent, canActivate: [AuthGuardCliente] },
      { path: 'admin/solicitudes',      component: SolicitudesComponent,          canActivate: [AuthGuardAdmin] },
      { path: 'admin/visitas',          component: VisitasComponent,              canActivate: [AuthGuardAdmin] },
      { path: 'admin/administradores',  component: AdministradoresComponent,      canActivate: [AuthGuardAdmin] },
      { path: 'admin/rep-pedidos',      component: ReportePedidosComponent,       canActivate: [AuthGuardAdmin] },
      { path: 'admin/clientes',         component: ClientesComponent,             canActivate: [AuthGuardAdmin] },
      { path: 'preferencias',           component: PreferenciasComponent,         canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
