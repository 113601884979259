import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService, StoreManagement, SessionService } from 'src/app/shared';
import { InventariosService } from './../inventarios.service';
import { InventariosApartadosService } from '../../inventario-apartado/inventario-apartado.service';

@Component({
    selector: 'int-menu-inventario',
    templateUrl: './menu-inventario.component.html',
    styleUrls: ['./menu-inventario.component.css']
})
export class MenuInventarioComponent implements OnInit {

    categorias: any[] = [];
    menu: any[] = [];
    cliente: any;

    @Input()
    menuSelected: any;
    @Input()
    EsApartado: boolean;
    @Output()
    selected: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router,
                private config: PortalConfigService,
                private invSrv: InventariosService,
                private invApartadoSrv: InventariosApartadosService,
                private load: CargandoService,
                private ssnStora: StoreManagement,
                private ssnSrv: SessionService,
                private msjSrv: MensajesService) {
        this.config.app = 'Inventarios';
    }

    ngOnInit() {
        this.menuSelected = null;
        this.getMenu();
    }

    private getUsuarioPerfil() {
        let usuarioPerfil: any = this.ssnStora.getPerfilUsuario();
        if (usuarioPerfil !== 'undefined') {
          usuarioPerfil = JSON.parse(usuarioPerfil);
          this.cliente = usuarioPerfil.id_intelisis;
        } else {
          this.cliente = this.ssnSrv.principal.idIntelisis;
        }
    }

    private getMenu(): void {
        if (this.EsApartado) {
            this.getUsuarioPerfil();
            this.load.toggle(true);
            this.invApartadoSrv.dameMenuApartado(this.cliente).subscribe(
                resMenu => {
                    this.menu = resMenu;
                    this.armaCategorias(resMenu);
                    this.load.toggle(false);
                },
                errMenu => {
                    this.load.toggle(false);
                    console.error('Error al consultar el menu::: ', errMenu);
                    this.msjSrv.agregaError('Error al obtener el menú, intente más tarde.');
                }
            );
        } else {
            this.load.toggle(true);
            this.invSrv.getMenu().subscribe(
                resMenu => {
                    this.menu = resMenu;
                    this.armaCategorias(resMenu);
                    this.load.toggle(false);
                },
                errMenu => {
                    this.load.toggle(false);
                    console.error('Error al consultar el menu::: ', errMenu);
                    this.msjSrv.agregaError('Error al obtener el menú, intente más tarde.');
                }
            );
        }
    }

    private armaCategorias(datos: any[]): void {
        this.categorias = [];
        let cat = null;
        let nombre = '';
        datos.forEach(e => {
            if (e.tarima === '0') {
                if (cat !== e.categoria) {
                    if (e.categoria === '1') {
                        nombre = 'Cartulinas Virgenes';
                    } else if (e.categoria === '2') {
                        nombre = 'Cartulinas Recicladas';
                    } else if (e.categoria === '3') {
                        nombre = 'Metalizados';
                    } else if (e.categoria === '4') {
                        nombre = 'Chromo';
                    } else {
                        nombre = 'Categoria no identificada';
                    }
                    cat = e.categoria;
                    this.categorias.push({code: cat, categoria: nombre});
                }
            }
        });
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    selectedElement(mTmp: any): void {
        this.menuSelected = mTmp;
        // this.muestraInventario = true;
        this.selected.emit(this.menuSelected);
        // console.log('Emite::: ', this.menuSelected);
    }

}
