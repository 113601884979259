import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substr'
})
export class SubstringPipe implements PipeTransform {

  constructor() { }

  transform(value: any, args = 5): any {
    if (value != null) {
        if(String(value).length <= args) {
            return String(value);
        } else {
            return String(value).substr(0, args) + '...';
        }
    } else {
        return '';
    }
  }
}
