import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from 'src/app/shared';

@Injectable()
export class DocumentosService {

    private relPath = {
        GetDocumentosURL: '/services/documentos/GetDocumentos.php',
        MuestraPDFURL: '/services/documentos/MuestraPDF.php'
    };

    constructor(@Inject('AUTH_API_ENDPOINT') private api: string,
                private http: HttpClient,
                private storeSrv: StoreManagement) { }

    GetDocumentos(tipo: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.GetDocumentosURL}`;
        const param = {seccion: tipo};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    MuestraPDF(ruta: any): Observable<any> {
        const url = `${this.api}${this.relPath.MuestraPDFURL}?ruta=${ruta}`;
        return this.http.get(url, { observe: 'response', responseType: 'blob' });
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
