import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { RepPedidosService } from './reporte-pedidos.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
    selector: 'int-rep-pedidos',
    templateUrl: './reporte-pedidos.component.html',
    styleUrls: ['./reporte-pedidos.component.css']
})
export class ReportePedidosComponent implements OnInit, AfterViewInit {

    displayedColumns = ['Pedido', 'Fecha', 'Cliente', 'Tipo', 'Carton', 'Kgs', 'Hojas', 'HojasReq', 'Bobinas'];
    pedidos: any[] = [];

    dataSource = new MatTableDataSource(this.pedidos);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    // @ViewChild(MatSort, {static: true}) sort: MatSort;

    tipoFiltro = null;
    fechaIni: Date = null;
    fechaFin: Date = null;
    fecha: Date = null;
    cliente: any = null;
    tipo: any = null;

    clientes: any[] = [];

    constructor(private router: Router,
                private config: PortalConfigService,
                private load: CargandoService,
                private dialog: MatDialog,
                private repSrv: RepPedidosService,
                private msjSrv: MensajesService) {
        this.config.app = 'Reporte de Pedidos';
    }

    ngOnInit() {
        this.tipoFiltro = null;
        this.getClientes();
        // this.getVisitas({tipoFiltro: this.tipoFiltro, fechaIni: null, fechaFin: null});
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    private getClientes(): void {
        this.load.toggle(true);
        this.repSrv.getClientes().subscribe(
            resCte => {
                this.clientes = resCte;
                this.load.toggle(false);
            }
        );
    }

    private isValidFilters(): boolean {
        if (this.tipoFiltro === undefined || this.tipoFiltro === null) {
            this.msjSrv.agregaWarn('Debe selecionar un tipo de busqueda.');
            return false;
        }

        if (this.tipoFiltro === 'c') {
            if (this.cliente === undefined || this.cliente === null && this.cliente === '') {
                this.msjSrv.agregaWarn('Debe selecionar un tipo de pedido.');
                return false;
            }
        }
        if (this.tipoFiltro === 'f') {
            if (this.fecha === undefined || this.fecha === null) {
                this.msjSrv.agregaWarn('Debe selecionar una fecha');
                return false;
            }
        }
        if (this.tipoFiltro === 'r') {
            if (this.fechaIni !== undefined && this.fechaIni !== null &&
                this.fechaFin !== undefined && this.fechaFin !== null) {
                if (this.fechaIni > this.fechaFin) {
                    this.msjSrv.agregaWarn('La fecha inicial no puede ser mayor a la final');
                    return false;
                }
            } else {
                this.msjSrv.agregaWarn('Debe selecionar un rango de fechas');
                return false;
            }
        }
        if (this.tipoFiltro === 't') {
            if (this.tipo === undefined || this.tipo === null) {
                this.msjSrv.agregaWarn('Debe selecionar un tipo de pedido.');
                return false;
            }
        }
        return true;
    }

    realizaFiltrado(): void {
        if (this.isValidFilters()) {
            this.load.toggle(true);
            this.repSrv.getHistorialPedidos(
                this.tipoFiltro, this.dateToStr(this.fecha),
                this.dateToStr(this.fechaIni),
                this.dateToStr(this.fechaFin),
                this.cliente, this.tipo).subscribe(
                resPed => {
                    // console.log(resPed);
                    this.pedidos = resPed;
                    this.dataSource = new MatTableDataSource(this.pedidos);
                    this.dataSource.paginator = this.paginator;
                    this.load.toggle(false);
                },
                errPed => {
                    console.error('Error al obtener la información:::', errPed);
                    this.msjSrv.agregaError('Error al obtener la información, intente más tarde.');
                    this.load.toggle(false);
                }
            );
        }
    }

    private dateToStr(fecha: Date): string {
        const datePipe = new DatePipe('es-MX');
        const value = datePipe.transform(fecha, 'dd-MM-yyyy');
        return value;
    }

    exportAsExcel(): void {
        if (this.pedidos.length > 0) {

            let pedido: any;
            const pedidosExport: any[] = [];
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.pedidos.length; i++) {
                pedido = this.pedidos[i];
                pedidosExport.push({
                    Pedido: pedido.token,
                    Fecha: pedido.fecha_registro,
                    Cliente: pedido.compania,
                    Tipo: (pedido.tipo_pedido as string).toUpperCase(),
                    Carton: pedido.nombre,
                    'Kgs. Aprox': pedido.kilos_aprox,
                    'Hojas Aprox': pedido.hojas_aprox,
                    'Hojas Req.': pedido.hojas,
                    'Bobinas Aprox': pedido.bobinas_hojear
                });
            }

            // converts a DOM TABLE element to a worksheet
            // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(pedidosExport);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Pedidos');
            /* save to file */
            XLSX.writeFile(wb, 'ReportePedidos.xlsx');
        } else {
            this.msjSrv.agregaWarn('No hay información para exportar.');
        }
    }

}
