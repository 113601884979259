import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from 'src/app/shared';

@Injectable()
export class PedidosService {

    private relPath = {
        dameHistorialPedidosURL: '/services/pedidos/dameHistorialPedidos.php',
        dameDetPedidosHistorialURL: '/services/pedidos/dameDetPedidosHistorial.php',
        damePedidosPendientesURL: '/services/pedidos/damePedidosPendientes.php',
        dameDetPedidoPendienteURL: '/services/pedidos/dameDetPedidoPendiente.php',
        GetClienteVIPURL: '/services/pedidos/GetClienteVIP.php',
        EliminarPartidaURL: '/services/pedidos/EliminarPartida.php',
        EliminarTodoURL: '/services/pedidos/EliminarTodo.php',
        EnviarPedidoURL: '/services/pedidos/EnviarPedido.php',
        CargaOrdenCompraURL: '/services/pedidos/CargaOrdenCompra.php'
    };

    constructor(@Inject('AUTH_API_ENDPOINT') private api: string,
                private http: HttpClient,
                private storeSrv: StoreManagement) { }

    dameHistorialPedidos(user: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameHistorialPedidosURL}`;
        const param = {cliente: user};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    dameDetPedidosHistorial(user: string, tokenId: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameDetPedidosHistorialURL}`;
        const param = {cliente: user, token: tokenId};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    damePedidosPendientes(user: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.damePedidosPendientesURL}`;
        const param = {cliente: user};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    dameDetPedidoPendiente(cte: string, tPedido: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameDetPedidoPendienteURL}`;
        const param = {cliente: cte, tipoPedido: tPedido};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    EliminarPartida(idPartida: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.EliminarPartidaURL}`;
        const param = {id: idPartida};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    EliminarTodo(cte: string, tPedido: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.EliminarTodoURL}`;
        const param = {cliente: cte, tipoPedido: tPedido };
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    EnviarPedido(cte: string, tPedido: string, suc: number, fecReq: string,
                 hrReq: string, OrdenC: string, rutaOrden: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.EnviarPedidoURL}`;
        const param = {
            cliente: cte,
            tipoPedido: tPedido,
            sucursal: suc,
            fechaRequerida: fecReq,
            horaRequerida: hrReq,
            OrderCompra: OrdenC,
            RutaOrden: rutaOrden
        };
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    CargaOrdenCompra(file: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const dataFile: FormData = new FormData();
        dataFile.append('file_upload', file, file.name);
        const url = `${this.api}${this.relPath.CargaOrdenCompraURL}`;
        return this.http.post<any>(url, dataFile, httpOptions);
    }

    GetClienteVIP(usuario: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.GetClienteVIPURL}`;
        const param = {username: usuario};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('GetClienteVIP', {})),
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
