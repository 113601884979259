import { Idle } from '@ng-idle/core';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MensajesService, CargandoService, LoginService, Auth2Service, HttpErrorsService, StoreManagement } from './../../shared';

@Component({
    selector: 'int-login-mobile',
    templateUrl: './login-mobile.component.html',
    styles: []
})
export class LoginMobileComponent {

    tokenId: string;

    constructor(private router: Router,
                private accessSrv: LoginService,
                private title: Title,
                private authSrv: Auth2Service,
                private httpErroSrv: HttpErrorsService,
                @Inject('IDLE_TIMEOUT') private timeout: number,
                private idle: Idle,
                private route: ActivatedRoute,
                private storeSrv: StoreManagement
                ) {
        this.title.setTitle('Acceso Inventarios Mobile');
        this.obtieneParametros();
    }

    private obtieneParametros(): void {
        this.route.queryParams.subscribe(params => {
            this.tokenId =  params.tokenId;
        });
        this.validaParametros();
    }

    private validaParametros(): void {
        if (this.tokenId === null || this.tokenId === undefined || this.tokenId === '') {
            window.location.reload();
            return;
        }
        this.onSubmit();
    }

    onSubmit() {
        this.storeSrv.saveToken(this.tokenId);
        this.accessSrv.perfil(this.tokenId).toPromise().then(
            usuario => {
                if (usuario.code === 0) {
                    this.authSrv.login(usuario);
                    if (this.idle.isRunning) {
                        this.idle.interrupt();
                    }
                    this.idle.watch();
                    if (this.authSrv.redirectUrl && this.authSrv.redirectUrl.length > 0) {
                        this.router.navigateByUrl(this.authSrv.redirectUrl);
                        this.authSrv.redirectUrl = null;
                    } else {
                        this.navegar('/');
                    }
                } else {
                    window.location.reload();
                }
            }).catch(error => {
                window.location.reload();
            });
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

}
