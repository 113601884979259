import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { VisitasService } from './visitas.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
    selector: 'int-vistas-ctes',
    templateUrl: './visitas.component.html',
    styleUrls: ['./visitas.component.css']
})
export class VisitasComponent implements OnInit, AfterViewInit {

    displayedColumns = ['Nombre', 'TotalDesktop', 'TotalMobile'];
    visitas: any[] = [];

    dataSource = new MatTableDataSource(this.visitas);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    // @ViewChild(MatSort, {static: true}) sort: MatSort;

    tipoFiltro = '';
    fechaIni: Date = null;
    fechaFin: Date = null;

    constructor(private router: Router,
                private config: PortalConfigService,
                private load: CargandoService,
                private dialog: MatDialog,
                private visSrv: VisitasService,
                private msjSrv: MensajesService) {
        this.config.app = 'Reporte de Visitas';
    }

    ngOnInit() {
        this.tipoFiltro = 'm';
        this.getVisitas({tipoFiltro: this.tipoFiltro, fechaIni: null, fechaFin: null});
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    realizaFiltrado(): void {
        if (this.tipoFiltro === 'r') {
            if (this.fechaIni !== undefined && this.fechaIni !== null &&
                this.fechaFin !== undefined && this.fechaFin !== null) {
                if (this.fechaIni < this.fechaFin) {
                    this.getVisitas(
                        {
                            tipoFiltro: this.tipoFiltro,
                            fechaIni: this.dateToStr(this.fechaIni),
                            fechaFin: this.dateToStr(this.fechaFin)
                        }
                    );
                } else {
                    this.msjSrv.agregaWarn('La fecha inicial no puede ser mayor a la final');
                }
            } else {
                this.msjSrv.agregaWarn('Debe selecionar un rango de fechas');
            }
        } else {
            this.getVisitas({tipoFiltro: this.tipoFiltro, fechaIni: null, fechaFin: null});
        }
    }

    private getVisitas(param: any): void {
        this.load.toggle(true);
        this.visitas = [];
        this.visSrv.getVisitas(param).subscribe(
            resVisitas => {
                this.visitas = resVisitas;
                this.dataSource = new MatTableDataSource(this.visitas);
                this.dataSource.paginator = this.paginator;
                // this.dataSource.sort = this.sort;
                this.load.toggle(false);
            },
            errVisitas => {
                console.error('Error al obtener las visitas', errVisitas);
                this.msjSrv.agregaError('Error al consultar las visitas, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    private dateToStr(fecha: Date): string {
        const datePipe = new DatePipe('es-MX');
        const value = datePipe.transform(fecha, 'dd-MM-yyyy');
        return value;
    }

    exportAsExcel(): void {
        if (this.visitas.length > 0) {

            let v: any;
            const visitasExport: any[] = [];
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.visitas.length; i++) {
                v = this.visitas[i];
                visitasExport.push({
                    Cliente: v.comp_largo,
                    'Total de Visitas Escritorio': v.total_desktop,
                    'Total de Visitas Móvil': v.total_mobile
                });
            }

            // converts a DOM TABLE element to a worksheet
            // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(visitasExport);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Visitas');
            /* save to file */
            XLSX.writeFile(wb, 'ReporteVisitas.xlsx');
        } else {
            this.msjSrv.agregaWarn('No hay información para exportar.');
        }
    }

}
