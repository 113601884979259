import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
    MensajesService, PortalConfigService, CargandoService,
    SessionService, ConfirmacionService
} from 'src/app/shared';
import { RecoveryPasswordService } from './recovery-password.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
@Component({
    selector: 'int-recovery-password',
    templateUrl: './recovery-password.component.html',
    styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent {

    desbloqueoForm: FormGroup;
    cambioForm: FormGroup;
    oculta = true;
    muestraPass = true;

    constructor(private router: Router,
                private builder: FormBuilder,
                private config: PortalConfigService,
                private dialog: MatDialog,
                private load: CargandoService,
                private ssnSrv: SessionService,
                private confirmSrv: ConfirmacionService,
                private recoverySrv: RecoveryPasswordService,
                private msjSrv: MensajesService) {
        this.config.app = 'Recuperar contraseña';
        this.inicializaForm();
        this.oculta = true;
    }

    private inicializaForm(): void {
        this.desbloqueoForm = this.builder.group({
            usuario: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        });

        this.cambioForm = this.builder.group({
            usuario: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            claveRecuperacion: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }

    solicitaClaveRecovery(): void {
        const mensaje = 'Se enviará un correo con la clave de recuperación para cambiar su contraseña, ¿Está de acuerdo?';
        this.confirmSrv.confirmarSimple(mensaje).subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.recoverySrv.SolicitarClaveRecuperacion(this.desbloqueoForm.value.usuario,
                        this.desbloqueoForm.value.email).toPromise().then(
                            resService => {
                                if (resService.code === 0) {
                                    this.cambiaVista();
                                    this.msjSrv.agregaInfo(resService.message);
                                    this.load.toggle(false);
                                } else {
                                    this.load.toggle(false);
                                    this.msjSrv.agregaError(resService.message);
                                }
                            }).catch(error => {
                                this.load.toggle(false);
                                console.log('Error ==> ', error);
                                this.msjSrv.agregaError('Error al solicitar la clave de recuperación, intente más tarde');
                            });
                }
            });
    }

    cambiarPassword(): void {
        const mensaje = 'Su contraseña se cambiará por la ingresada, ¿Desea continuar?';
        this.confirmSrv.confirmarSimple(mensaje).subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.recoverySrv.CambioPassword(this.desbloqueoForm.value.usuario,
                        this.desbloqueoForm.value.email,
                        this.cambioForm.value.claveRecuperacion,
                        this.cambioForm.value.password).toPromise().then(
                            resService => {
                                if (resService.code === 0) {
                                    this.msjSrv.agregaInfo(resService.message);
                                    this.load.toggle(false);
                                    this.navegar('/login');
                                } else {
                                    this.load.toggle(false);
                                    this.msjSrv.agregaError(resService.message);
                                }
                            }).catch(error => {
                                this.load.toggle(false);
                                console.log('Error ==> ', error);
                                this.msjSrv.agregaError('Error al cambiar la contraseña, intente más tarde.');
                            });
                }
            });
    }

    cambiaVista(): void {
        this.cambioForm.controls.usuario.patchValue(this.desbloqueoForm.value.usuario);
        this.cambioForm.controls.email.patchValue(this.desbloqueoForm.value.email);
        this.cambioForm.controls.usuario.disable();
        this.cambioForm.controls.email.disable();
        this.cambioForm.controls.claveRecuperacion.patchValue(null);
        this.cambioForm.controls.password.patchValue(null);
        this.oculta = false;
    }

    cambiaVistaSolicitar(): void {
        this.cambioForm.controls.usuario.enable();
        this.cambioForm.controls.email.enable();
        this.cambioForm.reset();
        this.oculta = true;
    }

    volverEnviar(): void {
        this.load.toggle(true);
        this.recoverySrv.ReenvioClaveRecuperacion(this.desbloqueoForm.value.usuario, this.desbloqueoForm.value.email).toPromise().then(
            resService => {
                if (resService.code === 0) {
                    this.msjSrv.agregaInfo(resService.message);
                    this.load.toggle(false);
                } else if (resService.code === 2) {
                    this.msjSrv.agregaWarn(resService.message);
                    this.cambiaVistaSolicitar();
                    this.load.toggle(false);
                } else {
                    this.load.toggle(false);
                    this.msjSrv.agregaError(resService.message);
                }
            }).catch(error => {
                this.load.toggle(false);
                console.log('Error ==> ', error);
                this.msjSrv.agregaError('Error al solicitar la clave de recuperación.');
            });
    }

    cancelar(): void {
        this.navegar('/login');
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    irRegistrar(): void {
        this.navegar('/registro');
    }

}
