import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialIntModule } from '../shared';
import { LoginRoutingModule } from './login.routing';
import { LoginComponent } from './login.component';
import { NumericField } from './../shared/directivas/NumericField';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistroService } from './registro/registro.service';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { RecoveryPasswordService } from './recovery-password/recovery-password.service';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { LogoutMobileComponent } from './logout-mobile/logout-mobile.component';

import { IntSharedModule } from './../shared';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialIntModule,
    IntSharedModule,
    LoginRoutingModule
  ],
  providers: [RegistroService, RecoveryPasswordService],
  declarations: [LoginComponent, RegistroComponent, NumericField,
                 LoginMobileComponent, RecoveryPasswordComponent,
                 LoginAdminComponent, LogoutMobileComponent]
})
export class LoginModule { }
