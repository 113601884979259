import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CargandoService, MensajesService, SessionService, ConfirmacionService } from 'src/app/shared';
import { ClientesService } from './../clientes.service';
import { SolicitudesService } from './../../solicitudes/solicitudes.service';

@Component({
    selector: 'int-dlg-merge-cte',
    templateUrl: './dlg-merge-cliente.component.html',
    styleUrls: ['dlg-merge-cliente.component.css']
})
export class DlgDetClienteComponent implements OnInit {

    clienteSelected: any;
    agentes: any = [];
    usuarioForm: FormGroup;
    estados: any[] = [];
    oculta = true;
    isEdit: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<DlgDetClienteComponent>,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private confirmSrv: ConfirmacionService,
                private builder: FormBuilder,
                private dialog: MatDialog,
                private cteSrv: ClientesService,
                private solSrv: SolicitudesService) {
    }

    ngOnInit(): void {
        this.limpiaVariables();
        this.InitComponente();
    }

    private limpiaVariables(): void {
        this.getAgentes();
        this.llenaEstados();
    }

    private InitComponente(): void {
        this.clienteSelected = this.params.clienteSelected as any;
        this.isEdit = this.params.isEdit as boolean;
        this.creaForm();
        if (this.isEdit) {
            this.setForm(this.clienteSelected);
        } else {
            this.validaUpdatePass(null);
        }
    }

    private creaForm(): void {
        this.usuarioForm = this.builder.group({
            id: [null, Validators.nullValidator],
            username: [null, Validators.required],
            password: [null, null],
            updatePass: [false, Validators.nullValidator],
            idIntelisis: [null, Validators.required],
            status: [null, Validators.required],

            tipoPersona: [null, Validators.required],
            companyName: [null, null],
            rfc: [null, [Validators.nullValidator,
                // tslint:disable-next-line: max-line-length
                Validators.pattern('^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$')]],

            direccion: [null, [Validators.nullValidator]],
            ciudad: [null, [Validators.nullValidator]],
            estado: [null, [Validators.nullValidator]],
            cp: [null, [Validators.nullValidator, Validators.minLength(5), Validators.maxLength(5)]],
            telefono: [null, [Validators.nullValidator, Validators.minLength(10), Validators.maxLength(15)]],

            nombre1: [null, null],
            email1: [null, null],
            cargo1: [null, null],

            nombre2: [null, null],
            email2: [null, null],
            cargo2: [null, null],

            nombre: [null, null],
            apellido: [null, null],

            correo: [null, [Validators.email]],
            Agente: [null, [Validators.required]],
            minVtaTarima: [null, [Validators.required]],
            largoMin: [null, [Validators.required]],
            largoMax: [null, [Validators.required]],
            b_vendedor: [null, [Validators.required]]
        });
    }

    private setForm(sel: any): void {
        // console.log(JSON.stringify(sel));
        this.usuarioForm.controls.id.patchValue(sel.id);
        this.usuarioForm.controls.username.patchValue(sel.username);
        this.usuarioForm.controls.username.disable();

        // this.usuarioForm.controls.password.patchValue(sel.password);
        // this.usuarioForm.controls.password.patchValue(null);
        this.usuarioForm.controls.idIntelisis.patchValue(sel.id_intelisis);
        this.usuarioForm.controls.status.patchValue(sel.status);

        this.usuarioForm.controls.tipoPersona.patchValue(sel.tipo_empresa);
        this.usuarioForm.controls.companyName.patchValue(sel.compania);
        this.usuarioForm.controls.rfc.patchValue(sel.rfc);

        this.usuarioForm.controls.direccion.patchValue(sel.direccion);
        this.usuarioForm.controls.ciudad.patchValue(sel.ciudad);
        this.usuarioForm.controls.estado.patchValue(sel.estado);
        this.usuarioForm.controls.cp.patchValue(sel.cp);
        this.usuarioForm.controls.telefono.patchValue(sel.telefono);

        this.usuarioForm.controls.nombre1.patchValue(sel.contacto1);
        this.usuarioForm.controls.email1.patchValue(sel.email1);
        this.usuarioForm.controls.cargo1.patchValue(sel.cargo1);

        this.usuarioForm.controls.nombre2.patchValue(sel.contacto2);
        this.usuarioForm.controls.email2.patchValue(sel.email2);
        this.usuarioForm.controls.cargo2.patchValue(sel.cargo2);

        this.usuarioForm.controls.nombre.patchValue(sel.nombre);
        this.usuarioForm.controls.apellido.patchValue(sel.apellido);

        this.usuarioForm.controls.correo.patchValue(sel.correo);
        this.usuarioForm.controls.Agente.patchValue(sel.Agente);

        this.usuarioForm.controls.minVtaTarima.patchValue(sel.min_vta_tarima);
        this.usuarioForm.controls.largoMin.patchValue(sel.largoMin);
        this.usuarioForm.controls.largoMax.patchValue(sel.largoMax);
        this.usuarioForm.controls.b_vendedor.patchValue(sel.b_vendedor);

        this.validaUpdatePass(null);
    }

    validaUpdatePass(event: any): void {

        if (!this.isEdit) {
            this.usuarioForm.controls.updatePass.patchValue(true);
            this.usuarioForm.controls.updatePass.disable();
            this.usuarioForm.controls.updatePass.updateValueAndValidity();
            this.usuarioForm.updateValueAndValidity();
        }

        if (this.usuarioForm.getRawValue().updatePass) {
            this.usuarioForm.controls.password.patchValue(null);
            this.usuarioForm.controls.password.enable();
            this.usuarioForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
            this.usuarioForm.controls.password.updateValueAndValidity();
            this.usuarioForm.updateValueAndValidity();
        } else {
            this.usuarioForm.controls.password.patchValue(null);
            this.usuarioForm.controls.password.disable();
            this.usuarioForm.controls.password.setValidators([]);
            this.usuarioForm.controls.password.updateValueAndValidity();
            this.usuarioForm.updateValueAndValidity();
        }
    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    private getAgentes(): void {
        this.load.toggle(true);
        this.agentes = [];
        this.solSrv.getAgentes().subscribe(
            resAgent => {
                this.agentes = resAgent;
                this.load.toggle(false);
            },
            errAgent => {
                this.load.toggle(false);
                console.error('Error al consultar los Agentes::: ', errAgent);
                this.msjSrv.agregaError('Error al obtener los agentes, intente más tarde.');
            }
        );
    }

    cambiaTipo(event: any): void {
        if (this.usuarioForm.value.tipoPersona === 'moral') {
            // Moral si va
            // this.usuarioForm.controls.companyName.setValue(null);
            this.usuarioForm.controls.companyName.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.companyName.updateValueAndValidity();

            // this.usuarioForm.controls.nombre1.setValue(null);
            this.usuarioForm.controls.nombre1.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre1.updateValueAndValidity();

            // this.usuarioForm.controls.email1.setValue(null);
            this.usuarioForm.controls.email1.setValidators([Validators.nullValidator, Validators.email]);
            this.usuarioForm.controls.email1.updateValueAndValidity();

            // this.usuarioForm.controls.cargo1.setValue(null);
            this.usuarioForm.controls.cargo1.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.cargo1.updateValueAndValidity();

            // this.usuarioForm.controls.nombre2.setValue(null);
            this.usuarioForm.controls.nombre2.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre2.updateValueAndValidity();

            // this.usuarioForm.controls.email2.setValue(null);
            this.usuarioForm.controls.email2.setValidators([Validators.nullValidator, Validators.email]);
            this.usuarioForm.controls.email2.updateValueAndValidity();

            // this.usuarioForm.controls.cargo2.setValue(null);
            this.usuarioForm.controls.cargo2.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.cargo2.updateValueAndValidity();

            // Fisica no va
            // this.usuarioForm.controls.nombre.setValue(null);
            this.usuarioForm.controls.nombre.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre.updateValueAndValidity();

            // this.usuarioForm.controls.apellido.setValue(null);
            this.usuarioForm.controls.apellido.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.apellido.updateValueAndValidity();

            // this.usuarioForm.controls.correo.setValue(null);
            this.usuarioForm.controls.correo.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.correo.updateValueAndValidity();

            this.usuarioForm.updateValueAndValidity();
        } else {
            // Fisica si va
            // this.usuarioForm.controls.nombre.setValue(null);
            this.usuarioForm.controls.nombre.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre.updateValueAndValidity();

            // this.usuarioForm.controls.apellido.setValue(null);
            this.usuarioForm.controls.apellido.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.apellido.updateValueAndValidity();

            // this.usuarioForm.controls.email.setValue(null);
            this.usuarioForm.controls.correo.setValidators([Validators.nullValidator, Validators.email]);
            this.usuarioForm.controls.correo.updateValueAndValidity();
            // Moral no va
            // this.usuarioForm.controls.companyName.setValue(null);
            this.usuarioForm.controls.companyName.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.companyName.updateValueAndValidity();

            // this.usuarioForm.controls.nombre1.setValue(null);
            this.usuarioForm.controls.nombre1.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre1.updateValueAndValidity();

            // this.usuarioForm.controls.email1.setValue(null);
            this.usuarioForm.controls.email1.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.email1.updateValueAndValidity();

            // this.usuarioForm.controls.cargo1.setValue(null);
            this.usuarioForm.controls.cargo1.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.cargo1.updateValueAndValidity();

            // this.usuarioForm.controls.nombre2.setValue(null);
            this.usuarioForm.controls.nombre2.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.nombre2.updateValueAndValidity();

            // this.usuarioForm.controls.email2.setValue(null);
            this.usuarioForm.controls.email2.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.email2.updateValueAndValidity();

            // this.usuarioForm.controls.cargo2.setValue(null);
            this.usuarioForm.controls.cargo2.setValidators([Validators.nullValidator]);
            this.usuarioForm.controls.cargo2.updateValueAndValidity();

            this.usuarioForm.updateValueAndValidity();
        }
    }

    private llenaEstados(): void {
        this.estados = [];
        this.estados.push({value: null, viewValue: 'Seleccione...'});
        this.estados.push({value: 'AGUASCALIENTES', viewValue: 'AGUASCALIENTES'});
        this.estados.push({value: 'BAJA CALIFORNIA', viewValue: 'BAJA CALIFORNIA'});
        this.estados.push({value: 'BAJA CALIFORNIA SUR', viewValue: 'BAJA CALIFORNIA SUR'});
        this.estados.push({value: 'CAMPECHE', viewValue: 'CAMPECHE'});
        this.estados.push({value: 'CHIAPAS', viewValue: 'CHIAPAS'});
        this.estados.push({value: 'CHIHUAHUA', viewValue: 'CHIHUAHUA'});
        this.estados.push({value: 'COAHUILA', viewValue: 'COAHUILA'});
        this.estados.push({value: 'COLIMA', viewValue: 'COLIMA'});
        this.estados.push({value: 'DISTRITO FEDERAL', viewValue: 'DISTRITO FEDERAL'});
        this.estados.push({value: 'DURANGO', viewValue: 'DURANGO'});
        this.estados.push({value: 'GUANAJUATO', viewValue: 'GUANAJUATO'});
        this.estados.push({value: 'GUERRERO', viewValue: 'GUERRERO'});
        this.estados.push({value: 'HIDALGO', viewValue: 'HIDALGO'});
        this.estados.push({value: 'JALISCO', viewValue: 'JALISCO'});
        this.estados.push({value: 'MEXICO', viewValue: 'MÉXICO'});
        this.estados.push({value: 'MICHOACAN', viewValue: 'MICHOACAN'});
        this.estados.push({value: 'MORELOS', viewValue: 'MORELOS'});
        this.estados.push({value: 'NAYARIT', viewValue: 'NAYARIT'});
        this.estados.push({value: 'NUEVO LEON', viewValue: 'NUEVO LEÓN'});
        this.estados.push({value: 'OAXACA', viewValue: 'OAXACA'});
        this.estados.push({value: 'PUEBLA', viewValue: 'PUEBLA'});
        this.estados.push({value: 'QUERETARO', viewValue: 'QUERÉTARO'});
        this.estados.push({value: 'QUINTANA ROO', viewValue: 'QUINTANA ROO'});
        this.estados.push({value: 'SAN LUIS POTOSI', viewValue: 'SAN LUIS POTOSÍ'});
        this.estados.push({value: 'SINALOA', viewValue: 'SINALOA'});
        this.estados.push({value: 'SONORA', viewValue: 'SONORA'});
        this.estados.push({value: 'TABASCO', viewValue: 'TABASCO'});
        this.estados.push({value: 'TAMAULIPAS', viewValue: 'TAMAULIPAS'});
        this.estados.push({value: 'TLAXCALA', viewValue: 'TLAXCALA'});
        this.estados.push({value: 'VERACRUZ', viewValue: 'VERACRUZ'});
        this.estados.push({value: 'YUCATAN', viewValue: 'YUCATÁN'});
        this.estados.push({value: 'ZACATECAS', viewValue: 'ZACATECAS'});
    }

    isRequiredField(field: string) {
        const formField = this.usuarioForm.get(field);
        if (formField === null) {
            return false;
        }
        if (!formField.validator) {
            return false;
        }
        const validator = formField.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    updateCte(): void {
        if (this.isEdit) {
            this.confirmSrv.confirmarSimple('Se actualizará la información del cliente, ¿Está seguro de que desea continuar?').subscribe(
                res => {
                    if (res) {
                        // console.log(JSON.stringify(this.usuarioForm.value));
                        this.load.toggle(true);
                        this.cteSrv.actualizaCliente(this.usuarioForm.value).subscribe(
                            resUpdate => {
                                if (resUpdate.code === 0) {
                                    this.msjSrv.agregaInfo(resUpdate.message);
                                    this.load.toggle(false);
                                    this.cerrarDialogo();
                                } else {
                                    this.msjSrv.agregaError(resUpdate.message);
                                    this.load.toggle(false);
                                }
                            },
                            errUpdate => {
                                console.error('updateCte::Error al actualizar el cliente', errUpdate);
                                this.msjSrv.agregaError('Error al actualizar el cliente, intente más tarde.');
                                this.load.toggle(false);
                            }
                        );
                    }
                }
            );
        } else {
            this.confirmSrv.confirmarSimple('Se creará el cliente, ¿Está seguro de que desea continuar?').subscribe(
                res => {
                    if (res) {
                        // console.log(JSON.stringify(this.usuarioForm.value));
                        this.load.toggle(true);
                        this.cteSrv.GeneraCliente(this.usuarioForm.value).subscribe(
                            resInsert => {
                                if (resInsert.code === 0) {
                                    this.msjSrv.agregaInfo(resInsert.message);
                                    this.load.toggle(false);
                                    this.cerrarDialogo();
                                } else {
                                    this.msjSrv.agregaError(resInsert.message);
                                    this.load.toggle(false);
                                }
                            },
                            errUpdate => {
                                console.error('resInsert::Error al crear el cliente', errUpdate);
                                this.msjSrv.agregaError('Error al crear el cliente, intente más tarde.');
                                this.load.toggle(false);
                            }
                        );
                    }
                }
            );
        }
    }
}
