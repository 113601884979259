import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CargandoService, MensajesService, ConfirmacionService, SessionService, StoreManagement } from 'src/app/shared';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { InventariosService } from '../inventarios.service';
import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
    selector: 'int-dlg-add-pedido',
    templateUrl: 'add-pedido.component.html',
    styleUrls: ['add-pedido.component.css'],
})
export class DlgAddPedidoComponent implements OnInit {

    menuSelected: any;
    inventario: any;

    pedidoForm: FormGroup;
    ocultaTarima = false;
    ventaMinima: number;
    largoMin: number;
    largoMax: number;

    username: string;
    idUsuario: number;
    aplicaPerfil: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private builder: FormBuilder,
                private dlgSrv: ConfirmacionService,
                private ssnServ: SessionService,
                private invServ: InventariosService,
                private ssnStora: StoreManagement,
                private atp: AmazingTimePickerService,
                private dialogRef: MatDialogRef<DlgAddPedidoComponent>) {
    }

    ngOnInit(): void {
        this.ventaMinima = -1;
        this.menuSelected = this.data.menuSelected;
        this.inventario = this.data.inventario;
        this.armaForm();
        // console.log('Menu Seleccionado:::', this.menuSelected);
        this.getDatosPerfil();
    }

    private getDatosPerfil() {
        if (this.ssnServ.principal.bVendedor === 'V') {
            const usuarioPerfil = this.ssnStora.getPerfilUsuario();
            let perfilArray: any;
            if (usuarioPerfil !== 'undefined') {
                perfilArray = JSON.parse(usuarioPerfil);
                this.username = perfilArray.username;
                this.idUsuario = perfilArray.id;
                this.aplicaPerfil = true;
            } else {
                this.username = this.ssnServ.principal.username;
                this.idUsuario = this.ssnServ.principal.id;
                this.aplicaPerfil = false;
            }
        } else {
            this.username = this.ssnServ.principal.username;
            this.idUsuario = this.ssnServ.principal.id;
            this.aplicaPerfil = false;
        }
        this.getVentaMinima();
    }

    private getVentaMinima(): void {
        this.load.toggle(true);
        this.invServ.getVentaMinima(this.idUsuario).subscribe(
            resVta => {
                if (resVta.min_vta_tarima) {
                    this.ventaMinima = resVta.min_vta_tarima;
                } else {
                    this.ventaMinima = -1;
                }
                if (resVta.largoMin) {
                    this.largoMin = resVta.largoMin;
                } else {
                    this.largoMin = 55;
                }
                if (resVta.largoMax) {
                    this.largoMax = resVta.largoMax;
                } else {
                    this.largoMax = 175;
                }
                // console.log('Venta minima...', this.ventaMinima);
                this.cambiaTipo(null);
                this.load.toggle(false);
            },
            errVta => {
                console.error('Error al obtener la venta minima...', errVta);
                this.load.toggle(false);
            }
        );
    }

    private armaForm(): void {
        this.pedidoForm = this.builder.group({
            tipo: ['T', [Validators.required]],
            largoTarima: [null, null],
            hojasTarima: [null, null],
            numBobinas: [null, null],
            kilosAproxBobinas: [null, null],
            observaciones: [null, [Validators.required]]
        });
        this.cambiaTipo(null);
    }

    cambiaTipo(event: any): void {
        this.pedidoForm.controls.largoTarima.setValidators(null);
        this.pedidoForm.controls.hojasTarima.setValidators(null);
        this.pedidoForm.controls.numBobinas.setValidators(null);
        this.pedidoForm.controls.kilosAproxBobinas.setValidators(null);
        if (this.pedidoForm.value.tipo === 'T') {
            this.ocultaTarima = false;
            this.pedidoForm.controls.largoTarima.setValue(null);
            this.pedidoForm.controls.hojasTarima.setValue(null);
            this.pedidoForm.controls.largoTarima.setValidators(
                [
                    Validators.required,
                    Validators.min(this.largoMin),
                    Validators.max(this.largoMax)]);
            this.pedidoForm.controls.hojasTarima.setValidators([Validators.required, this.validaMultiplo]);

            this.pedidoForm.controls.numBobinas.setValue(null);
            this.pedidoForm.controls.kilosAproxBobinas.setValue(null);
            this.pedidoForm.controls.numBobinas.setValidators(Validators.nullValidator);
            this.pedidoForm.controls.kilosAproxBobinas.setValidators(Validators.nullValidator);
        } else {
            this.ocultaTarima = true;
            this.pedidoForm.controls.largoTarima.setValue(null);
            this.pedidoForm.controls.hojasTarima.setValue(null);
            this.pedidoForm.controls.largoTarima.setValidators(Validators.nullValidator);
            this.pedidoForm.controls.hojasTarima.setValidators(Validators.nullValidator);

            this.pedidoForm.controls.numBobinas.setValue(null);
            this.pedidoForm.controls.kilosAproxBobinas.setValue(null);
            this.pedidoForm.controls.numBobinas.setValidators([Validators.required, Validators.max(Number(this.inventario.n_bobinas))]);
            this.pedidoForm.controls.kilosAproxBobinas.setValidators(Validators.required);
        }
        Promise.resolve().then(() => {
            this.pedidoForm.controls.largoTarima.updateValueAndValidity();
            this.pedidoForm.controls.hojasTarima.updateValueAndValidity();
            this.pedidoForm.controls.numBobinas.updateValueAndValidity();
            this.pedidoForm.controls.kilosAproxBobinas.updateValueAndValidity();
        });
    }

    calculaBobina(): void {
        let pesoProm = 0;
        if (this.pedidoForm.value.numBobinas === undefined ||
            this.pedidoForm.value.numBobinas === null ||
            this.pedidoForm.value.numBobinas === 0 ) {
            pesoProm = 0;
        } else {
            pesoProm = Math.ceil(
                (
                    Math.round(Number(this.inventario.peso) / Number(this.inventario.n_bobinas)) *
                    (Number(this.pedidoForm.value.numBobinas))
                ));
        }
        // tslint:disable-next-line: use-isnan
        if (pesoProm === NaN) {
            this.pedidoForm.controls.kilosAproxBobinas.patchValue(0);
        } else {
            this.pedidoForm.controls.kilosAproxBobinas.patchValue(pesoProm);
        }
    }

    aceptar(): void {
        if (this.validaVentaMinima()) {
            let mensageAdvertencia = 'Se agregará la partida al pedido. ¿Desea continuar?';
            if (this.aplicaPerfil) {
                mensageAdvertencia = 'Se agregará la partida al pedido para el usuario [' + this.username + ']. ¿Desea continuar?';
            }
            if (this.pedidoForm.value.tipo === 'T') {
                this.load.toggle(true);
                this.invServ.getGramaje(this.inventario.carton_id, this.inventario.calibre).subscribe(
                    resGramaje => {
                        const tarimaRequerida =
                            Math.round((Number(this.inventario.ancho) / 100) *
                                       (Number(this.pedidoForm.getRawValue().largoTarima) / 100) *
                                       (resGramaje.gramaje_calculo / 1000) *
                                       Number(this.pedidoForm.getRawValue().hojasTarima));
                        if (Number(this.inventario.peso) > Number(tarimaRequerida)) {
                            this.dlgSrv.confirmarSimple(mensageAdvertencia).subscribe(
                                res => {
                                    if (res) {
                                        this.insert_pedido();
                                    }
                                }
                            );
                        } else {
                            this.msjSrv.agregaWarn('Lo sentimos excedes la existencia. Disponible: [' +
                             Number(this.inventario.peso) + ' kgs] Requeridas [' + tarimaRequerida + ' kgs]');
                        }
                        this.load.toggle(false);
                    },
                    errGramaje => {
                        this.msjSrv.agregaError('Error al validar la existencia, intente nuevamente.');
                        console.error('Error al calcular la existencia: ', errGramaje);
                        this.load.toggle(false);
                    }
                );
            } else {
                this.dlgSrv.confirmarSimple(mensageAdvertencia).subscribe(
                    res => {
                        if (res) {
                            this.insert_pedido();
                        }
                    }
                );
            }
        }
    }

    cancelar(): void {
        this.dlgSrv.confirmarSimple('No ha agregado el producto. ¿Desea continuar?').subscribe(
            res => {
              if (res) {
                this.dialogRef.close();
              }
        });
    }

    private insert_pedido(): void {
        this.load.toggle(true);
        const params = {
            token: this.ssnServ.principal.tokenPedido,
            // cliente: this.ssnServ.principal.username,
            cliente: this.username,
            tipo_pedido: (this.pedidoForm.value.tipo === 'T' ? 'tarima' : 'bobina'),
            id_carton: this.inventario.carton_id,
            id_calibre: this.inventario.calibre,
            ancho: this.inventario.ancho,
            largo: this.pedidoForm.getRawValue().largoTarima,
            hojas: this.pedidoForm.getRawValue().hojasTarima,
            bobinas_hojear: 0,
            hojas_aprox: 0,
            kilos_aprox: 0,
            numero_bobinas: this.pedidoForm.getRawValue().numBobinas,
            kilos_aprox_bobina: this.pedidoForm.getRawValue().kilosAproxBobinas,
            observaciones: this.pedidoForm.value.observaciones,
            id_menu: this.menuSelected.id
        };
        this.invServ.insertaPedido(params).subscribe(
            resInsert => {
                if (resInsert.code === 0) {
                    this.msjSrv.agregaOK(resInsert.message);
                    this.dialogRef.close();
                } else {
                    this.msjSrv.agregaError(resInsert.message);
                }
                this.load.toggle(false);
            },
            errInsert => {
                console.error('Error al insertar la partida:::', errInsert);
                this.msjSrv.agregaError('Error al insertar la partida, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    private validaVentaMinima(): boolean {
        if (this.pedidoForm.value.tipo === 'T') {
            if (this.ventaMinima === -1) {
                return true;
            } else {
                console.log('Hojas Tarima::: ', this.pedidoForm.getRawValue().hojasTarima);
                console.log('Venta Minima::: ', this.ventaMinima);
                console.log();
                if (Number(this.pedidoForm.getRawValue().hojasTarima) < Number(this.ventaMinima)) {
                    this.msjSrv.agregaWarn('Su venta mínima de hojas es de [' + this.ventaMinima + '].');
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    private validaMultiplo(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined) {
            const resto = control.value % 50;
            if (resto === 0) {
                // console.log('Valido Valor:[' + control.value + '] Resto: [' + resto + ']');
                return null;
            } else {
                // console.log('Invalido Valor:[' + control.value + '] Resto: [' + resto + ']');
                return { multiplo : true };
            }
        } else {
            return { multiplo : true };
        }
    }

}
