import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService, SessionService, ConfirmacionService } from 'src/app/shared';
import { PreferenciasService } from './preferencias.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
@Component({
    selector: 'int-preferencias',
    templateUrl: './preferencias.component.html',
    styleUrls: ['./preferencias.component.css']
})
export class PreferenciasComponent implements OnInit {

    nombre: string;
    apellido: string;
    isAdmin: boolean;
    usuario: string;
    company: string;

    changePwdForm: FormGroup;
    fielConfig = { actual: false, nueva: false, confirma: false };
    expandedPwd = false;
    expandedEmail = false;
    expandedBotones = false;
    expandedNotificaciones = false;
    eMailCtrl: FormControl;

    constructor(private router: Router,
                private builder: FormBuilder,
                private config: PortalConfigService,
                private dialog: MatDialog,
                private load: CargandoService,
                private ssnSrv: SessionService,
                private confirmSrv: ConfirmacionService,
                private prefSrv: PreferenciasService,
                private msjSrv: MensajesService) {
        this.config.app = 'Preferencias';

        this.changePwdForm = builder.group({
            actual: [null, Validators.required],
            nueva: [null, [Validators.required, Validators.minLength(5)]],
            confirma: [null, [Validators.required, Validators.minLength(5)]]
        }, { validator: this.pwdMatchValidator });
        this.eMailCtrl = builder.control(null, [Validators.required, Validators.email]);
        this.eMailCtrl.patchValue(this.ssnSrv.principal.correo);
        this.changePwdForm.reset();
    }

    ngOnInit() {
        this.nombre = this.ssnSrv.principal.nombre;
        this.apellido = this.ssnSrv.principal.apellido;
        this.isAdmin = this.ssnSrv.principal.isAdmin;
        this.usuario = this.ssnSrv.principal.username;
        this.company = this.ssnSrv.principal.compania;
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    pwdMatchValidator(frm: FormGroup) {
        return (frm.get('nueva').dirty && frm.get('confirma').dirty
          && frm.get('nueva').value !== frm.get('confirma').value)
          ? { mismatch: true } : null;
    }

    changePassword(): void {
        this.confirmSrv.confirmarSimple('Se cambiará su contraseño. ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.prefSrv.cambiaPassword(this.ssnSrv.principal.id,
                                                this.changePwdForm.value.actual,
                                                this.changePwdForm.value.nueva,
                                                this.ssnSrv.principal.isAdmin).subscribe(
                        resPass => {
                            if (resPass.code === 0) {
                                this.msjSrv.agregaOK(resPass.message);
                                this.changePwdForm.reset();
                            } else {
                                this.msjSrv.agregaError(resPass.message);
                            }
                            this.load.toggle(false);
                        },
                        errPass => {
                            console.log('Error al cambiar la password::', errPass);
                            this.msjSrv.agregaError('Error al cambiar la contraseña, intente nuevamente.');
                            this.load.toggle(false);
                        }
                    );
                }
            }
        );
    }

    actualizaEmail(): void {
        this.confirmSrv.confirmarSimple('Se cambiará su dirección E-Mail. ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.prefSrv.cambiaEmail(this.ssnSrv.principal.id,
                                             this.eMailCtrl.value,
                                             this.ssnSrv.principal.isAdmin).subscribe(
                        resPass => {
                            if (resPass.code === 0) {
                                this.msjSrv.agregaOK(resPass.message);
                                this.ssnSrv.principal.correo = this.eMailCtrl.value;
                            } else {
                                this.msjSrv.agregaError(resPass.message);
                            }
                            this.load.toggle(false);
                        },
                        errPass => {
                            console.log('Error al cambiar el email::', errPass);
                            this.msjSrv.agregaError('Error al cambiar la dirección de E-MAIL, intente nuevamente.');
                            this.load.toggle(false);
                        }
                    );
                }
            }
        );
    }

    ejecutarSincronizacion(): void {
        const mensaje = 'Se ejecutara la sincronización, puede durar varios minutos. ¿Desea continuar?';
        this.confirmSrv.confirmarSimple(mensaje).subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.prefSrv.sincronizarIntelisis().subscribe(
                        resSync => {
                            if (resSync.code === 0) {
                                this.msjSrv.agregaOK(resSync.message);
                            } else {
                                this.msjSrv.agregaError(resSync.message);
                            }
                            this.load.toggle(false);
                        },
                        errSync => {
                            console.log('Error al ejecutar la sincronización::', errSync);
                            this.msjSrv.agregaError('Error al ejecutar la sincronización, intente más tarde.');
                            this.load.toggle(false);
                        }
                    );
                }
            }
        );
    }
}
