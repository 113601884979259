import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { Mensaje } from '../../models';

@Component({
    selector: 'int-mensajes',
    template: `
    <div fxFlex="80" [class]="'mensaje '+mensaje?.tipo" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon >{{ icon }}</mat-icon>
            <span fxFlex="70" fxFlexOffset="10"> {{mensaje?.mensaje}}</span>
        </div>
        <button fxFlex mat-button (click)="cerrar()">Ok</button>
    </div>
  `,
    styleUrls: ['./mensajes.component.css']
})
export class MensajesComponent {

    private cerrarS = new Subject<any>();
    cerrar$ = this.cerrarS.asObservable();
    mensaje: Mensaje;

    constructor() {
    }

    get icon(): string {
        return this.mensaje ?
            (this.mensaje.tipo ?
                (this.mensaje.tipo === 'info' ? 'info' :
                    (this.mensaje.tipo === 'warn' ? 'warning' :
                        (this.mensaje.tipo === 'error' ? 'cancel' :
                            'done_all')
                    )
                ) :
                'done_all') :
            'done_all';
    }

    cerrar(): void {
        this.cerrarS.next('cerrar');
    }

}
