import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PedidosService } from '../pedidos.service';
import { CargandoService, MensajesService, SessionService, ConfirmacionService, StoreManagement } from 'src/app/shared';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { DlgEnviarPedidoComponent } from '../enviar-pedido';

@Component({
    selector: 'int-pedidos-pendientes',
    templateUrl: 'pedidos-pendientes.component.html',
    styleUrls: ['pedidos-pendientes.component.css'],
})
export class PedidosPendietesComponent implements OnInit {

    @Output() updateHistorial = new EventEmitter();

    pedidos: any[];

    columnDisplayBobina: string[] = ['no', 'carton', 'calibre', 'ancho', 'bobinas', 'cantidad', 'accion'];
    columnDisplayTarima: string[] = ['no', 'carton', 'calibre', 'ancho', 'largo', 'hReq', 'accion'];
    columnDisplayPrimera: string[] = ['no', 'carton', 'medidas', 'calibre', 'hojas', 'peso', 'tarimas', 'accion'];

    username: string;
    aplicaPerfil: boolean;
    perfilUsuario: any;

    constructor(private pedidoSrv: PedidosService,
                private load: CargandoService,
                private ssSrv: SessionService,
                private dialog: MatDialog,
                private ssnStora: StoreManagement,
                private dlgSrv: ConfirmacionService,
                private msjSrv: MensajesService) {
    }

    ngOnInit(): void {
        this.pedidos = [];
        this.getDatosPerfil();
    }

    private getDatosPerfil() {
        if (this.ssSrv.principal.bVendedor === 'V') {
            const usuarioPerfil = this.ssnStora.getPerfilUsuario();
            this.perfilUsuario = {};
            if (usuarioPerfil !== 'undefined') {
                this.perfilUsuario = JSON.parse(usuarioPerfil);
                this.username = this.perfilUsuario.username;
                this.aplicaPerfil = true;
            } else {
                this.username = this.ssSrv.principal.username;
                this.aplicaPerfil = false;
            }
        } else {
            this.username = this.ssSrv.principal.username;
            this.aplicaPerfil = false;
        }
        this.getPedidosPendientes();
    }

    private getPedidosPendientes(): void {
        this.load.toggle(true);
        this.pedidoSrv.damePedidosPendientes(this.username).subscribe(
            resPedidos => {
                this.pedidos = [];
                let pedidoTmp = {};
                let dataSource: MatTableDataSource<any[]>;
                // tslint:disable-next-line: prefer-for-of
                for (let index = 0; index < resPedidos.length; index++) {
                    const item = resPedidos[index];
                    this.pedidoSrv.dameDetPedidoPendiente(this.username, item.tipo_pedido).subscribe(
                        resDet => {
                            dataSource = new MatTableDataSource(resDet);
                            pedidoTmp = { tipo_pedido: item.tipo_pedido,
                                          fecha_registro: item.fecha_registro,
                                          detalle: dataSource
                                        };
                            this.pedidos.push(pedidoTmp);
                        },
                        errDet => {
                            console.error('Error al obtener el detalle del pedido:::', errDet);
                            this.msjSrv.agregaError('Error al obtener el detalle del pedido, intente más tarde.');
                        }
                    );
                }
                this.load.toggle(false);
            },
            errHist => {
                console.error('Error al obtener los pedidos', errHist);
                this.msjSrv.agregaError('Error al obtener los pedidos, intenta más tarde.');
                this.load.toggle(false);
            }
        );
    }

    eliminarPartida(item: any): void {
        this.dlgSrv.confirmarSimple('Se eliminará la partida: Producto [' +
            item.nombre_producto + '] Calibre [' +
            item.id_calibre + ']. ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.pedidoSrv.EliminarPartida(item.id).subscribe(
                        resEnv => {
                            if (resEnv.code === 0) {
                                this.msjSrv.agregaOK(resEnv.message);
                                this.ngOnInit();
                            } else {
                                this.msjSrv.agregaError(resEnv.message);
                            }
                            this.load.toggle(false);
                        },
                        errEnv => {
                            console.error('Error al enviar el pedido', errEnv);
                            this.msjSrv.agregaError('Error al enviar el pedido, intente más tarde.');
                            this.load.toggle(false);
                        }
                    );
                }
        });
    }

    eliminarTodo(item: any): void {
        const pipe = new DatePipe('es-MX');
        const myFormattedDate = pipe.transform(item.fecha_registro , 'dd/MM/yyyy HH:mm:ss');
        this.dlgSrv.confirmarSimple('Se eliminará el pedido: Tipo [' +
            (item.tipo_pedido === 'tp' ? 'Tarima de Primera' : item.tipo_pedido).toUpperCase() + '] Fecha [' +
            myFormattedDate + '] ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.pedidoSrv.EliminarTodo(this.username,
                                                item.tipo_pedido).subscribe(
                        resEnv => {
                            if (resEnv.code === 0) {
                                this.msjSrv.agregaOK(resEnv.message);
                                this.ngOnInit();
                            } else {
                                this.msjSrv.agregaError(resEnv.message);
                            }
                            this.load.toggle(false);
                        },
                        errEnv => {
                            console.error('Error al enviar el pedido', errEnv);
                            this.msjSrv.agregaError('Error al enviar el pedido, intente más tarde.');
                            this.load.toggle(false);
                        }
                    );
                }
            }
        );
    }

    enviarPedido(item: any): void {
        this.dialog.open(DlgEnviarPedidoComponent, {
            width: '95%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                pedidoSelected: item,
                username: this.username
            }
        }).afterClosed().subscribe(resClose => {
            if (resClose) {
                // console.log(JSON.stringify(resClose));
                const pipe = new DatePipe('es-MX');
                const myFormattedDate = pipe.transform(item.fecha_registro , 'dd/MM/yyyy HH:mm:ss');
                this.dlgSrv.confirmarSimple('Se enviará el pedido: Tipo [' +
                (item.tipo_pedido === 'tp' ? 'Tarima de Primera' : item.tipo_pedido).toUpperCase() + '] Fecha [' +
                    myFormattedDate + '] ¿Desea continuar?').subscribe(
                    res => {
                        if (res) {

                            // Si el archivo es nulo solo Inserta pedido
                            if (resClose.FileOrdenCompra === null) {
                                this.load.toggle(true);
                                this.RegistraPedido(item, resClose, null);
                            } else {
                                this.load.toggle(true);
                                this.pedidoSrv.CargaOrdenCompra(resClose.FileOrdenCompra).subscribe(
                                    resUpload => {
                                        const responseFile = resUpload[0];
                                        // console.log('Cargar Response:: ', responseFile);
                                        if (responseFile.code === 0) {
                                            this.RegistraPedido(item, resClose, responseFile.ruta);
                                        } else {
                                            this.msjSrv.agregaError(responseFile.message);
                                            this.load.toggle(false);
                                        }
                                    },
                                    errUpload => {
                                        console.error('Error al cargar el archivo:: ', errUpload);
                                        this.msjSrv.agregaError('Error al cargar la orden de compra, intente nuevamente.');
                                        this.load.toggle(false);
                                    }
                                );
                            }
                        }
                    }
                );
            }
        });
    }

    private RegistraPedido(item: any, datos: any, pathFile: any): void {
        let idSucursal = 0;
        if (datos.sucursal) {
            idSucursal = datos.sucursal.id;
        }
        this.pedidoSrv.EnviarPedido(this.username, item.tipo_pedido,
                                    idSucursal, datos.fechaRequerida,
                                    datos.horaRequerida, datos.OrderCompra, pathFile).subscribe(
            resEnv => {
                if (resEnv.code === 0) {
                    this.msjSrv.agregaOK(resEnv.message);
                    this.ngOnInit();
                    this.updateHistorial.emit(new Date());
                } else {
                    this.msjSrv.agregaError(resEnv.message);
                }
                this.load.toggle(false);
            },
            errEnv => {
                console.error('Error al enviar el pedido', errEnv);
                this.msjSrv.agregaError('Error al enviar el pedido, intente más tarde.');
                this.load.toggle(false);
            }
        );
    }

}
