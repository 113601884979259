import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Identity } from './../models/identity';

@Injectable()
export class SessionService {

  // tslint:disable-next-line: variable-name
  private isLoggedOn_ = false;
  // tslint:disable-next-line: variable-name
  private hasToken_ = false;
  // tslint:disable-next-line: variable-name
  private hasUser_ = false;
  // tslint:disable-next-line: variable-name
  private hasSubUser_ = false;
  // tslint:disable-next-line: variable-name
  private isAdmin_ = false;

  private isLoggedOnS = new Subject<boolean>();
  private hasTokenS = new Subject<boolean>();
  private hasUserS = new Subject<boolean>();
  private hasSubUserS = new Subject<boolean>();
  private isAdminS = new Subject<boolean>();

  isLoggedOn$: Observable<boolean>;
  hasToken$: Observable<boolean>;
  hasUser$: Observable<boolean>;
  hasSubUser$: Observable<boolean>;
  isAdmin$: Observable<boolean>;

  // auth props

  principal: Identity;
  token: string;
  // ui control

  private showPasswordChange = new Subject<boolean>();
  showPasswordChange$: Observable<boolean>;

  constructor() {
    this.isLoggedOn$ = this.isLoggedOnS.asObservable();
    this.hasToken$ = this.hasTokenS.asObservable();
    this.hasUser$ = this.hasUserS.asObservable();
    this.hasSubUser$ = this.hasSubUserS.asObservable();
    this.isAdmin$ = this.isAdminS.asObservable();

    this.showPasswordChange$ = this.showPasswordChange.asObservable();
  }

  set isLoggedOn(isLoggedOn: boolean) {
    this.isLoggedOnS.next(isLoggedOn);
    this.isLoggedOn_ = isLoggedOn;
  }

  set hasToken(hasToken: boolean) {
    this.hasTokenS.next(hasToken);
    this.hasToken_ = hasToken;
  }

  set hasUser(hasUser: boolean) {
    this.hasUserS.next(hasUser);
    this.hasUser_ = hasUser;
  }

  set isAdmin(isAdmin: boolean) {
    this.isAdminS.next(isAdmin);
    this.isAdmin_ = isAdmin;
  }


// tslint:disable-next-line: adjacent-overload-signatures
  get isLoggedOn(): boolean {
    return this.isLoggedOn_;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  get hasToken(): boolean {
    return this.hasToken_;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  get hasUser(): boolean {
    return this.hasUser_;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  get isAdmin(): boolean {
    return this.isAdmin_;
  }

  isPasswordChange(is: boolean) {
    this.showPasswordChange.next(is);
  }

}
