import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, SessionService, StoreManagement, CargandoService } from 'src/app/shared';
import { MatDialog } from '@angular/material';
import { SelectorUsuariosComponent } from '../selector-usuarios';
import { InventariosApartadosService } from '../inventario-apartado';
import { MenuAppService, PortalToHomeService, HomeToPortalService } from '../menu';
import { Subscription } from 'rxjs';

@Component({
    selector: 'int-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit, OnDestroy {

    apps: any[];
    over0 = -1;
    over1 = -1;
    over2 = -1;
    isAdmin: boolean;
    isVendedor: boolean;
    muestraAviso: boolean;
    muestraPerfil: boolean;
    usuarioPerfil: any;

    muestraApartado: boolean;
    idIntelisis: any;

    subscription: Subscription;

    constructor(private router: Router,
                private config: PortalConfigService,
                public ssnServ: SessionService,
                private ssnStora: StoreManagement,
                private load: CargandoService,
                private invAprSrv: InventariosApartadosService,
                private dialog: MatDialog,
                private portalToHomeSrv: PortalToHomeService,
                private homeToPortalSrv: HomeToPortalService,
                private menuSrv: MenuAppService,
                private msjSrv: MensajesService) {
        this.config.app = 'Inicio';
        this.subscription = this.portalToHomeSrv.Check().subscribe(
            value => {
                // console.log('InicioComponent::Se ha realizo cambio en el menu del portal::: ', value);
                this.getUsuarioPerfil();
                this.getInvEspecial();
            }
        );
    }

    ngOnInit() {
        this.muestraAviso = false;
        this.getUsuarioPerfil();
        if (this.ssnServ.principal.correo === null) {
          this.muestraAviso = true;
        }
        this.isAdmin = this.ssnServ.principal.isAdmin;
        this.isVendedor = (this.ssnServ.principal.bVendedor === 'V' ? true : false);
        this.getInvEspecial();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private getUsuarioPerfil() {
        this.muestraPerfil = false;
        this.muestraApartado = false;
        this.usuarioPerfil = this.ssnStora.getPerfilUsuario();
        if (this.usuarioPerfil !== 'undefined') {
            this.muestraPerfil = true;
            this.usuarioPerfil = JSON.parse(this.usuarioPerfil);
            this.idIntelisis = this.usuarioPerfil.id_intelisis;
        } else {
            this.idIntelisis = this.ssnServ.principal.idIntelisis;
        }
    }

    private getInvEspecial(): void {
        this.load.toggle(true);
        this.invAprSrv.TieneInventarioEspecial(this.idIntelisis).subscribe(
            resInv => {
                if (resInv.Cuantos > 0) {
                    this.muestraApartado = true;
                } else {
                    this.muestraApartado = false;
                }
                this.ReconstruyeMenu();
            },
            errInv => {
                console.error('Error al obtener si aplica inventario especial:: ', errInv);
                this.muestraApartado = false;
                this.ReconstruyeMenu();
            }
        );
    }

    quitaSeleccion(): void {
        this.ssnStora.removePerfilUsuario();
        this.getUsuarioPerfil();
        this.getInvEspecial();
        this.homeToPortalSrv.Poll();
    }

    private ReconstruyeMenu(): void {
        this.load.toggle(true);
        this.apps = [];
        const EsAdmin: string = (this.isAdmin ? 'V' : 'F');
        this.menuSrv.GetMenu(EsAdmin, 'F').subscribe(
            resMenu => {
                // tslint:disable-next-line: prefer-for-of
                for (let index = 0; index < resMenu.length; index++) {
                    const item = resMenu[index];
                    if (item.Clave === 'InvApartado') {
                        if (this.muestraApartado) {
                            this.apps.push(item);
                        }
                    } else {
                        if (item.Clave === 'Perfil') {
                            if (this.isVendedor) {
                                this.apps.push(item);
                            }
                        } else {
                            this.apps.push(item);
                        }
                    }
                }
                this.load.toggle(false);
            },
            errMenu => {
                console.error('Error al generar le menu...', errMenu);
                this.load.toggle(false);
            },
        );
    }

    irA(ruta: string) {
        if (ruta && String(ruta).length) {
            if (ruta === '0') {
                this.openSelectorUsuario();
            } else {
                this.navegar(ruta);
            }
        } else {
            this.msjSrv.agregaWarn('Ruta de aplicación no configurada');
        }
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    openSelectorUsuario(): void {
        this.dialog.open(SelectorUsuariosComponent, {
            width: '55%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: { }
        }).afterClosed().subscribe(resClose => {
            if (resClose) {
                if (resClose) {
                    this.ngOnInit();
                    this.homeToPortalSrv.Poll();
                }
            }
        });
    }

}
