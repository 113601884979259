import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService } from 'src/app/shared';


@Component({
    selector: 'int-calculadora-menu',
    templateUrl: './cal_menu.component.html',
    styleUrls: ['./cal_menu.component.css']
})
export class CalculadoraMenuComponent implements OnInit {

    apps: any[];
    over0 = -1;
    over1 = -1;
    over2 = -1;

    constructor(private router: Router,
                private config: PortalConfigService,
                private msjSrv: MensajesService) {
        this.config.app = 'Calculadoras';
    }

    ngOnInit() {
        this.apps = [];
        this.apps.push({ Id: 1, Nombre: 'De hojas a kilos', Descripcion: 'De hojas a kilos', Situacion: true,
                         Ruta: '/calculo/1', Icono: 'fullscreen', URLImagen: null });

        this.apps.push({ Id: 2, Nombre: 'De kilos a hojas', Descripcion: 'De kilos a hojas', Situacion: true,
                         Ruta: '/calculo/2', Icono: 'fullscreen_exit', URLImagen: null });

        this.apps.push({ Id: 3, Nombre: 'De hojas a m2', Descripcion: 'De hojas a m2', Situacion: true,
                         Ruta: '/calculo/3', Icono: 'unfold_less', URLImagen: null });

        this.apps.push({ Id: 4, Nombre: 'Precio x hoja', Descripcion: 'Precio x hoja', Situacion: true,
                         Ruta: '/calculo/4', Icono: 'unfold_more', URLImagen: null });

        this.apps.push({ Id: 5, Nombre: 'Precio x millar', Descripcion: 'Precio x millar', Situacion: true,
                         Ruta: '/calculo/5', Icono: 'show_chart', URLImagen: null });

        this.apps.push({ Id: 6, Nombre: 'Precio x m2', Descripcion: 'Precio x m2', Situacion: true,
                         Ruta: '/calculo/6', Icono: 'center_focus_weak', URLImagen: null });
    }

    irA(ruta: string) {
        if (ruta && String(ruta).length) {
            this.navegar(ruta);
        } else {
            this.msjSrv.agregaWarn('Ruta de aplicación no configurada');
        }
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

}
