import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService, SessionService, StoreManagement } from 'src/app/shared';
import { PedidosService } from './pedidos.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { DlgDetPedidoHistorialComponent } from './detalle-historial';

@Component({
    selector: 'int-pedidos',
    templateUrl: './pedidos.component.html',
    styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

    historialPedidos: any[] = [];
    columnasHistorial: string[] = ['no', 'fecha', 'hora', 'action'];

    dataSourceHistorial: MatTableDataSource<any[]>;
    @ViewChild(MatPaginator, {static: true}) paginatorHistorial: MatPaginator;

    username: string;
    aplicaPerfil: boolean;
    perfilUsuario: any;

    constructor(private router: Router,
                private config: PortalConfigService,
                private dialog: MatDialog,
                private load: CargandoService,
                private ssnSrv: SessionService,
                private pedidoSrv: PedidosService,
                private ssnStora: StoreManagement,
                private msjSrv: MensajesService) {
        this.config.app = 'Pedidos';
    }

    ngOnInit() {
        this.getDatosPerfil();
    }

    private getDatosPerfil() {
        if (this.ssnSrv.principal.bVendedor === 'V') {
            const usuarioPerfil = this.ssnStora.getPerfilUsuario();
            this.perfilUsuario = {};
            if (usuarioPerfil !== 'undefined') {
                this.perfilUsuario = JSON.parse(usuarioPerfil);
                this.username = this.perfilUsuario.username;
                this.aplicaPerfil = true;
            } else {
                this.username = this.ssnSrv.principal.username;
                this.aplicaPerfil = false;
            }
        } else {
            this.username = this.ssnSrv.principal.username;
            this.aplicaPerfil = false;
        }
        this.getHistorialPedidos();
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    private getHistorialPedidos(): void {
        this.load.toggle(true);
        this.historialPedidos = [];
        this.pedidoSrv.dameHistorialPedidos(this.username).subscribe(
            resHist => {
                let num = 1;
                resHist.forEach(e => {
                    this.historialPedidos.push({numero: num, token: e.token, fecha_registro: e.fecha_registro});
                    num++;
                });
                this.dataSourceHistorial = new MatTableDataSource(this.historialPedidos);
                this.dataSourceHistorial.paginator = this.paginatorHistorial;
                this.load.toggle(false);
            },
            errHist => {
                this.load.toggle(false);
                console.error('Error al obtener pedidos historial::: ', errHist);
                this.msjSrv.agregaError('Error al obtener el historial de pedidos, inetente más tarde.');
            }
        );
    }

    openDetalleHistorial(item: any): void {
        this.dialog.open(DlgDetPedidoHistorialComponent, {
            width: '90%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                tokenId: item.token,
                cliente: this.username
            }
        });
    }

    updateHistorial($event: any) {
        // console.log('Cambia...', $event);
        this.getHistorialPedidos();
    }
}
