import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialIntModule } from './material-int.module';
import { StoreManagement } from './utils';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { MensajesComponent, DialogoCargaComponent, DialogoConfirmacionComponent, LogoutButtonComponent } from './components';
import { CargandoService, ConfirmacionService, MensajesService, FadeInOutComponent } from './components';
import { HttpErrorsService, LoginService, IntAppsService, DataService,
    Auth2Service, AuthGuard, AuthGuardAdmin, AuthGuardCliente,
    Login2Guard, IdlebootService, PortalConfigService } from './services';
import { SessionService } from './services/session.service';
import { NullPipe, TruncatePipe, SafeHtmlPipe, BsizePipe, FormErrorsPipe, SubstringPipe } from './pipes';

@NgModule({
    declarations: [
        MensajesComponent,
        DialogoCargaComponent,
        DialogoConfirmacionComponent,
        NullPipe,
        TruncatePipe,
        SafeHtmlPipe,
        LogoutButtonComponent,
        BsizePipe,
        FadeInOutComponent,
        FormErrorsPipe,
        SubstringPipe,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialIntModule,
        NgIdleKeepaliveModule.forRoot()
    ],
    providers: [
        Auth2Service,
        AuthGuard,
        AuthGuardAdmin,
        AuthGuardCliente,
        Login2Guard,
        IdlebootService,
        SessionService,
        PortalConfigService],
    exports: [
        MensajesComponent,
        DialogoCargaComponent,
        DialogoConfirmacionComponent,
        NullPipe,
        TruncatePipe,
        SafeHtmlPipe,
        LogoutButtonComponent,
        BsizePipe,
        FadeInOutComponent,
        FormErrorsPipe,
        SubstringPipe
    ],
    entryComponents: [
        DialogoCargaComponent,
        DialogoConfirmacionComponent,
        MensajesComponent
    ]
})
export class IntSharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: IntSharedModule,
            providers: [
                StoreManagement,
                HttpErrorsService,
                CargandoService,
                ConfirmacionService,
                MensajesService,
                LoginService,
                IntAppsService,
                DataService,
                Auth2Service,
                AuthGuard,
                AuthGuardAdmin,
                AuthGuardCliente,
                Login2Guard,
                IdlebootService,
                SessionService,
                PortalConfigService
            ]
        };
    }
}
@NgModule({
    exports: [IntSharedModule],
    providers: [
        StoreManagement,
        HttpErrorsService,
        CargandoService,
        ConfirmacionService,
        MensajesService,
        LoginService,
        IntAppsService,
        DataService
    ],
    declarations: []
})
export class IntSharedRootModule { }
