import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MensajeConfirmacion } from '../../models';

@Component({
    selector: 'int-dialogo-confirmacion',
    template: `
    <h2 style="text-align: center" *ngIf="mensaje.encabezado" mat-dialog-title>{{mensaje?.encabezado}}</h2>
    <mat-dialog-content *ngIf="mensaje.mensaje" >
      <h3 style="text-align: center">
        {{mensaje?.mensaje}} <mat-icon >{{ dameIcono() }}</mat-icon>
      </h3>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
      <button mat-button (click)="dialogRef.close('OK')">Aceptar</button>
      <button mat-button *ngIf="!mensaje.ocultaCancel" (click)="dialogRef.close('CA')">Cancelar</button>
    </mat-dialog-actions>
  `
})
export class DialogoConfirmacionComponent {

    mensaje: MensajeConfirmacion;

    constructor(public dialogRef: MatDialogRef<DialogoConfirmacionComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
      this.mensaje = data as MensajeConfirmacion;
      if (this.mensaje.ocultaCancel === null) {
        this.mensaje.ocultaCancel = false;
      }

    }

    dameIcono(): string {
      if (this.mensaje.tipo === 'info') {
        return 'info';
      }

      if (this.mensaje.tipo === 'warn') {
        return 'warning';
      }

      if (this.mensaje.tipo === 'error') {
        return 'error';
      }

      if (this.mensaje.tipo === 'timer') {
        return 'schedule';
      }

      if (this.mensaje.tipo === 'question') {
        return 'help';
      }

      return 'done_all';

    }
}
