import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CargandoService, MensajesService, ConfirmacionService, SessionService } from 'src/app/shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { DatePipe } from '@angular/common';
import { InventariosService } from './../../inventarios/inventarios.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { PedidosService } from '../pedidos.service';

@Component({
    selector: 'int-dlg-send-pedido',
    templateUrl: 'enviar-pedido.component.html',
    styleUrls: ['enviar-pedido.component.css'],
})
export class DlgEnviarPedidoComponent implements OnInit {

    pedidoSelected: any;
    sucursales: any[] = [];
    pedidoForm: FormGroup;
    hoy = new Date();
    minDate = new Date();
    fecha24hrs = new Date();
    username: any;

    maxSize = 3;
    @ViewChild('fileUploader', null) fileUploader;
    fileName: string;
    fileOrdenCompra: File;
    permiteHorario: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private builder: FormBuilder,
                private dlgSrv: ConfirmacionService,
                private invServ: InventariosService,
                private pedidoServ: PedidosService,
                private atp: AmazingTimePickerService,
                private dialogRef: MatDialogRef<DlgEnviarPedidoComponent>) {
    }

    ngOnInit(): void {
        // Si son las 13:00 se permite 24 hrs si no 48 hrs
        if (this.hoy.getHours() <= 13) {
            if (this.hoy.getHours() === 13) {
                if (Number(this.hoy.getMinutes()) <= 0) {
                    this.minDate.setDate(this.minDate.getDate() + 1);
                } else {
                    this.minDate.setDate(this.minDate.getDate() + 2);
                }
            } else {
                this.minDate.setDate(this.minDate.getDate() + 1);
            }
        } else {
            this.minDate.setDate(this.minDate.getDate() + 2);
        }
        this.fecha24hrs.setDate(this.fecha24hrs.getDate() + 1);
        this.pedidoSelected = this.data.pedidoSelected;
        this.username = this.data.username;
        this.fileOrdenCompra = null;
        this.permiteHorario = false;
        this.armaForm();
        this.dameSucursales();
    }

    open() {
        const amazingTimePicker = this.atp.open();
        amazingTimePicker.afterClose().subscribe(time => { console.log('Time Selected:: ', time); });
    }

    private armaForm(): void {
        this.pedidoForm = this.builder.group({
            sucursal: [null, [Validators.required]],
            fechaRequerida: [null, [Validators.required]],
            horaRequerida: [null, [Validators.required]],
            OrderCompra: [null, null],
        });
    }

    private dameSucursales(): void {
        this.load.toggle(true);
        this.sucursales = [];
        this.invServ.dameSucursales(this.username).subscribe(
            resSuc => {
                if (resSuc.length > 0) {
                    this.sucursales.push(...resSuc);
                }
                this.load.toggle(false);
            },
            errSuc => {
                console.error('Error al buscar las sucursales:::', errSuc);
                this.msjSrv.agregaError('Error al obtener las sucursales, inetente más tarde.');
                this.load.toggle(false);
            }
        );
    }

    aceptar(): void {
        this.dlgSrv.confirmarSimple('Se enviara el pedido. ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    const params = {
                        sucursal: this.pedidoForm.value.sucursal,
                        fechaRequerida: this.dateToStr(this.pedidoForm.value.fechaRequerida),
                        horaRequerida: this.pedidoForm.getRawValue().horaRequerida,
                        FileOrdenCompra: this.fileOrdenCompra,
                        OrderCompra: this.pedidoForm.value.OrderCompra
                    };
                    this.dialogRef.close(params);
                }
            }
        );
    }

    validaHorario(type: string, event: MatDatepickerInputEvent<Date>) {
        this.load.toggle(true);
        this.pedidoServ.GetClienteVIP(this.username).subscribe(
            resCteVip => {
                console.log('Usuarios VIP: ', resCteVip);
                if (resCteVip.cte_vip > 0) {
                    const numberDay = (this.pedidoForm.value.fechaRequerida as Date).getDay();
                    if (numberDay !== 1) {
                        if (this.dateToStr(this.pedidoForm.value.fechaRequerida) === this.dateToStr(this.fecha24hrs)) {
                            if (this.hoy.getHours() <= 12) {
                                if (this.hoy.getHours() === 12) {
                                    if (Number(this.hoy.getMinutes()) <= 0) {
                                        this.permiteHorario = true;
                                    } else {
                                        this.permiteHorario = false;
                                    }
                                } else {
                                    this.permiteHorario = true;
                                }
                            } else {
                                this.permiteHorario = false;
                            }
                        } else {
                            this.permiteHorario = true;
                        }
                    } else {
                        this.permiteHorario = false;
                    }
                } else {
                    this.permiteHorario = false;
                }
                if (this.permiteHorario) {
                    this.pedidoForm.controls.horaRequerida.patchValue(null);
                    this.pedidoForm.controls.horaRequerida.setValidators([Validators.required]);
                    this.pedidoForm.controls.horaRequerida.enable();
                } else {
                    this.pedidoForm.controls.horaRequerida.patchValue(null);
                    this.pedidoForm.controls.horaRequerida.setValidators([Validators.nullValidator]);
                    this.pedidoForm.controls.horaRequerida.disable();
                }
                this.load.toggle(false);
            },
            errCteVip => {
                console.error('Error al validar si es un cliente VIP', errCteVip);
                this.msjSrv.agregaError('Error al validar el horario, intente nuevamente.');
                this.load.toggle(false);
            }
        );
    }

    cancelar(): void {
        this.dlgSrv.confirmarSimple('No se ha enviado el pedido. ¿Desea continuar?').subscribe(
            res => {
                if (res) {
                    this.dialogRef.close(false);
                }
            }
        );
    }

    private getToTime(): any {

    }

    private dateToStr(fecha: Date): string {
        const datePipe = new DatePipe('es-MX');
        const value = datePipe.transform(fecha, 'dd-MM-yyyy');
        return value;
    }

    openFileBrowser(event: any) {
        event.preventDefault();
        this.fileUploader.nativeElement.click();
    }

    showPreview(event: any) {
        const filePreview = event.target.files[0];
        const size = ((filePreview.size / 1024) / 1024).toFixed(2);
        if (Number(size) <= this.maxSize) {
          if (filePreview) {
            this.fileOrdenCompra = filePreview;
            this.fileName = filePreview.name;
          } else {
            this.fileOrdenCompra = null;
            this.fileName = 'No se ha seleccionado ningún archivo';
            this.msjSrv.agregaWarn('El archivo seleccionado es invalido.');
          }
        } else {
            this.fileOrdenCompra = null;
            this.fileName = 'No se ha seleccionado ningún archivo';
            this.msjSrv.agregaWarn('El archivo supera el limite de tamaño establecido. (Max. ' + this.maxSize + ' Mb)');
        }
    }

}
