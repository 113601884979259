import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { Mensaje } from '../../models';
import { MensajesComponent } from './mensajes.component';

// tslint:disable-next-line:no-namespace
export namespace TIPOS_MENSAJE {
    export const ERROR = 'error';
    export const WARN = 'warn';
    export const INFO = 'info';
    export const OK = 'ok';
}

@Injectable()
export class MensajesService {
    private snackRef: MatSnackBarRef<MensajesComponent>;

    constructor(private snackBar: MatSnackBar) {
    }

    agregaOK(mensaje: string, duracion = 10000) {
        this.agregaMensaje(TIPOS_MENSAJE.OK, mensaje, 0, duracion);
    }

    agregaInfo(mensaje: string, duracion = 10000) {
        this.agregaMensaje(TIPOS_MENSAJE.INFO, mensaje, 0, duracion);
    }

    agregaWarn(mensaje: string, codigo?: number, duracion = 10000) {
        this.agregaMensaje(TIPOS_MENSAJE.WARN, mensaje, (codigo || 0), duracion);
    }

    agregaError(mensaje: any, codigo?: number, duracion = 10000) {
        // console.log();
        const errorDisplay = mensaje.Message || mensaje.message;
        // console.log('errorDisplay', errorDisplay);
        const exceptionDisplay = mensaje.ExceptionMessage;
        // console.log('exceptionDisplay', exceptionDisplay);
        const stackDisplay = mensaje.InnerException;
        // console.log('stackDisplay', stackDisplay);
        const mensajeDisplay = (errorDisplay || mensaje.ExceptionMessage)
            ? `${errorDisplay}${mensaje.ExceptionMessage ? ', ' + stackDisplay : ''}`
            : JSON.stringify(mensaje);
        // console.log('agregaError', mensajeDisplay);
        this.agregaMensaje(TIPOS_MENSAJE.ERROR, mensajeDisplay, (codigo || 0), duracion);
    }

    agregaMensaje(t: string, m: string, codigo?: number, duracion = 10000) {
        this.agrega({ tipo: t, mensaje: m, codigo: (codigo || 0) }, duracion);
    }

    agrega(mensaje: Mensaje, duracion = 10000) {
        this.snackRef = this.snackBar.openFromComponent(MensajesComponent, { duration: duracion });
        this.snackRef.instance.mensaje = mensaje;
        this.snackRef.instance.cerrar$.subscribe(cerrar => this.snackRef.dismiss());
    }
}
