import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'int-dialog-result',
    templateUrl: 'dialog-result.component.html',
    styleUrls: [],
})
export class DialogResultComponent implements OnInit {

    resultado: string;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private dialogRef: MatDialogRef<DialogResultComponent>) {

    }

    ngOnInit(): void {
        this.resultado = this.data.resultado;
    }

    aceptar(): void {
        this.dialogRef.close();
    }

}
