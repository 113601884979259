import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from 'src/app/shared';

@Injectable()
export class InventariosService {

    private relPath = {
        dameMenuURL: '/services/inventarios/dameMenu.php',
        dameDetalleInventarioURL: '/services/inventarios/dameDetalleInventario.php',
        dameSucursalesURL: '/services/inventarios/dameSucursales.php',
        insertaPedidoURL: '/services/inventarios/insertaPedido.php',
        dameDetalleInventarioTarimaURL: '/services/inventarios/dameDetalleInventarioTarima.php',
        printInventario: '/services/reportes/GeneradorReportes.php',
        getVentaMinimaURL: '/services/inventarios/getVentaMinima.php',
        getGramajeURL: '/services/inventarios/getGramaje.php',
        insertaTarimaPedidoURL: '/services/inventarios/insertaPedidoTarima.php',
    };

    constructor(@Inject('AUTH_API_ENDPOINT') private api: string,
                private http: HttpClient,
                private storeSrv: StoreManagement) { }

    getMenu(): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameMenuURL}`;
        const param = {};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    dameDetalleInventario(cartonId: any, alm: any): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameDetalleInventarioURL}`;
        const param = {carton_id: cartonId, almacen: alm};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    dameSucursales(idCte: string): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameSucursalesURL}`;
        const param = {id_cliente: idCte};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    insertaPedido(param: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.insertaPedidoURL}`;
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    dameDetalleInventarioTarima(tipoCarton: any): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.dameDetalleInventarioTarimaURL}`;
        const param = {tipoTar: tipoCarton};
        return this.http
            .post<any[]>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', [])),
            );
    }

    getVentaMinima(idCte: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.getVentaMinimaURL}`;
        const param = {id: idCte};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('getVentaMinima', {})),
            );
    }

    imprimeBobina(almacen: any, cartonId: any, idMenu: any, cliente: string, EsApartado: string): Observable<any> {
        // tslint:disable-next-line: max-line-length
        const url = `${this.api}${this.relPath.printInventario}?tipo=B&almacen=${almacen}&carton_id=${cartonId}&id_menu=${idMenu}&es_apartado=${EsApartado}&cliente=${cliente}`;
        return this.http.get(url, { observe: 'response', responseType: 'blob' });
    }

    imprimeTarima(idTarima: any): Observable<any> {
        const url = `${this.api}${this.relPath.printInventario}?tipo=T&id_tarima=${idTarima}`;
        return this.http.get(url, { observe: 'response', responseType: 'blob' });
    }

    getFileLocal(nameFile: any): Observable<any> {
        const url = `${nameFile}`;
        return this.http.get(url, { observe: 'response', responseType: 'blob' });
    }

    getGramaje(idCarton: any, calibreTmp: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.getGramajeURL}`;
        const param = { id_carton: idCarton, calibre: calibreTmp };
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('getGramaje', {})),
            );
    }

    insertaPedidoTarima(param: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.insertaTarimaPedidoURL}`;
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
