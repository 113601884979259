import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PDFProgressData } from 'pdfjs-dist';
import { CargandoService, MensajesService } from 'src/app/shared';
import { HttpResponse } from '@angular/common/http';
import { InventariosService } from '../inventarios/inventarios.service';

@Component({
    selector: 'int-dialog-result',
    templateUrl: 'pdf-visor.component.html',
    styleUrls: ['pdf-visor.component.css'],
})
export class PDFVisorComponent implements OnInit {

    titulo: string;
    nombre: any;
    ocultaLoad = false;
    esData = false;
    nameFile: string;
    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
        private load: CargandoService,
        private invSrv: InventariosService,
        private msgSrv: MensajesService,
        private dialogRef: MatDialogRef<PDFVisorComponent>) {

    }

    ngOnInit(): void {
        this.titulo = this.data.info;
        this.nombre = this.data.url;
        this.esData = this.data.esData;
        this.nameFile = this.data.nameFile;
    }

    aceptar(): void {
        this.dialogRef.close();
    }

    onProgress(progressData: PDFProgressData) {
        if (progressData.loaded === progressData.total) {
            this.ocultaLoad = true;
        }
    }

    descargar() {
        this.load.toggle(true);
        if (!this.esData) {
            this.load.toggle(true);
            const anchor = document.createElement('a');
            anchor.setAttribute('href', this.nombre);
            anchor.setAttribute('download', '');
            document.body.appendChild(anchor);
            anchor.click();
            anchor.parentNode.removeChild(anchor);
            this.load.toggle(false);
        } else {
            this.extractContent(this.nombre.body, this.nameFile);
        }
        this.load.toggle(false);
    }

    private extractContent(resBody: any, filename: string): void {
        this.load.toggle(true);
        const blob: Blob = resBody;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const url = window.URL || (window as any).webkitURL;
            var save: any = document.createElement('a');
            save.href = url.createObjectURL(blob);
            save.download = filename;
            var event = document.createEvent("MouseEvents");
            event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            save.dispatchEvent(event);

        }
        this.load.toggle(false);
    }

    imprimir(): void {
        if (this.esData) {
            this.load.toggle(true);
            this.printfile(this.nombre.body);
            this.load.toggle(false);
        } else {
            this.load.toggle(true);
            this.invSrv.getFileLocal(this.nombre).subscribe(
                resRep => {
                    this.printfile(resRep.body);
                    this.load.toggle(false);
                },
                errRep => {
                    console.error('Error al decargar el archivo...', errRep);
                    this.load.toggle(false);
                }
            );
        }
    }

    private printfile(bodyResponse: any): void {
        const blob: Blob = bodyResponse;
        const ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Edge') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            window.navigator.msSaveOrOpenBlob(blob, this.nameFile);
        } else {
            const url = window.URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = url;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
        }
    }


}
