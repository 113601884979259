import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService, SessionService, ConfirmacionService } from 'src/app/shared';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { DatePipe } from '@angular/common';
import { AdministradoresService } from './administradores.service';
import { DlgMergeAdminComponent } from './merge-admin';

@Component({
    selector: 'int-administradores-admin',
    templateUrl: './administradores.component.html',
    styleUrls: ['./administradores.component.css']
})
export class AdministradoresComponent implements OnInit {

    displayedColumns = ['username', 'nombre', 'correo', 'rol', 'estatus'];
    administradores: any[];
    adminSelected: any = null;

    dataSource = new MatTableDataSource([]);
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(private router: Router,
                private config: PortalConfigService,
                private load: CargandoService,
                private dialog: MatDialog,
                private ssSrv: SessionService,
                private adminSrv: AdministradoresService,
                private confirmSrv: ConfirmacionService,
                private msjSrv: MensajesService) {
        this.config.app = 'Administradores del Sistema';
    }

    ngOnInit() {
        this.administradores = [];
        this.adminSelected = null;
        this.getAdmins();
    }


    private getAdmins(): void {
        this.load.toggle(true);
        this.administradores = [];
        this.adminSelected = null;
        this.adminSrv.getAdministradores().subscribe(
            resAdmin => {
                this.administradores = resAdmin;
                this.dataSource = new MatTableDataSource(this.administradores);
                this.dataSource.paginator = this.paginator;
                this.load.toggle(false);
            },
            errAdmin => {
                console.error('Error al consultar los administradores:::', errAdmin);
                this.msjSrv.agregaError('Error al consultar los administradores');
                this.load.toggle(false);
            }
        );
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    seleccionaElemento(row: any): void {
        this.adminSelected = row;
    }

    editar(): void {
        const dialogRef = this.dialog.open(DlgMergeAdminComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                adminSelected: this.adminSelected,
                esNuevo: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

    nuevo(): void {
        const dialogRef = this.dialog.open(DlgMergeAdminComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                adminSelected: null,
                esNuevo: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

    changeSit(changeTo: any) {
        if (this.adminSelected.username === this.ssSrv.principal.username) {
            this.msjSrv.agregaWarn('Operación invalida sobre si mismo.');
        } else {
            let msg = 'Se inactivara el administrador [' + this.adminSelected.username + ']. ¿Desea continuar?';
            if (changeTo === '1') {
                msg = 'Se activara el administrador [' + this.adminSelected.username + ']. ¿Desea continuar?';
            }
            this.confirmSrv.confirmarSimple(msg).subscribe(
                res => {
                    if (res) {
                        this.load.toggle(true);
                        this.adminSrv.changeSituacion(this.adminSelected.id, changeTo).subscribe(
                            resChange => {
                                if (resChange.code === 0) {
                                    this.msjSrv.agregaInfo(resChange.message);
                                    this.load.toggle(false);
                                } else {
                                    this.msjSrv.agregaError(resChange.message);
                                    this.load.toggle(false);
                                }
                                this.ngOnInit();
                            },
                            errChange => {
                                console.error('mergeAdmin::Error al actualizar el admin', errChange);
                                this.msjSrv.agregaError('Error al actualizar el administrador, intente más tarde.');
                                this.load.toggle(false);
                            }
                        );
                    }
                }
            );
        }
    }
}
