import { Idle } from '@ng-idle/core';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService, Auth2Service, HttpErrorsService, StoreManagement, DataService } from './../../shared';
import { Observable } from 'rxjs';

@Component({
    selector: 'int-logout-mobile',
    templateUrl: './logout-mobile.component.html',
    styles: ['./logout-mobile.component.css']
})
export class LogoutMobileComponent {

    isReload: false;

    constructor(private router: Router,
                private accessSrv: LoginService,
                private title: Title,
                private authSrv: Auth2Service,
                private httpErroSrv: HttpErrorsService,
                @Inject('IDLE_TIMEOUT') private timeout: number,
                private idle: Idle,
                private paramSrv: DataService,
                private storeSrv: StoreManagement
                ) {
        this.title.setTitle('Salir Inventarios Mobile');
        this.reloadWindows();
    }

    private reloadWindows(): void {
        this.isReload = this.paramSrv.sessionMap.Realod;
        if (this.isReload) {
            console.log('LogoutMobileComponent::Se detecta Reload...');
            window.location.reload();
        } else {
            console.log('LogoutMobileComponent::No aplica Realod');
        }
    }


    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

}
