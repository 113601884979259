import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Auth2Service } from './auth2.service';
import { SessionService } from './session.service';


@Injectable()
export class AuthGuardAdmin implements CanActivate, CanActivateChild {

    constructor(private auth2Service: Auth2Service,
                private ssnSrv: SessionService,
                private router: Router) {
        // console.log('AuthGuard:constructor');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return Promise.resolve(this.auth2Service.isLoggedOn())
            .then(isloggedOn => {
                if (isloggedOn) {
                    if (this.ssnSrv.principal.isAdmin) {
                        // console.log('AuthGuardAdmin::Con autorizacion....');
                        return true;
                    } else {
                        // console.log('AuthGuardAdmin::Sin autorizacion....');
                        this.router.navigate(['noautorizado']);
                        return false;
                    }
                } else {
                    // console.log('AuthGuardAdmin::Sin logueo....');
                    this.auth2Service.redirectUrl = '/';
                    this.router.navigate(['login']);
                    return false;
                }
            });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

}
