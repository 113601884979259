import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Login2Guard } from '../shared';
import { LoginComponent } from './login.component';
import { RegistroComponent } from './registro/registro.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { LogoutMobileComponent } from './logout-mobile/logout-mobile.component';

const routes: Routes = [
  { path: 'login',             component: LoginComponent,            canActivate: [Login2Guard] },
  { path: 'login-mobile',      component: LoginMobileComponent,      canActivate: [Login2Guard] },
  { path: 'logout-mobile',     component: LogoutMobileComponent,     canActivate: [Login2Guard] },
  { path: 'recuperarpwd',      component: RecoveryPasswordComponent, canActivate: [Login2Guard] },
  { path: 'registro',          component: RegistroComponent,         canActivate: [Login2Guard] },
  { path: 'login-admin',       component: LoginAdminComponent,       canActivate: [Login2Guard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
