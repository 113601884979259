import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { Auth2Service } from './auth2.service';
import { Router } from '@angular/router';
import { ConfirmacionService } from '../components';
import { MensajeConfirmacion } from '../models';
import { MatDialog } from '@angular/material';

@Injectable()
export class IdlebootService {

    constructor(private idle: Idle,
                private keepalive: Keepalive,
                private router: Router,
                private dialogRef: MatDialog,
                private confSrv: ConfirmacionService,
                private authSrv: Auth2Service) {
    }

    start(timeout: number): void {
        console.log(`IdlebootService::start ${timeout}`);
        this.idle.setIdle(timeout - 5);
        this.idle.setTimeout(5);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => { console.log('Idle detenido'); });
        this.idle.onTimeout.subscribe(() => {
            this.stop();
            this.dialogRef.closeAll();
            this.authSrv.logout();
            const msj: MensajeConfirmacion = {
                encabezado: 'Su tiempo de sesión ha expirado',
                mensaje: 'Tu sesión ha expirado. Por favor, inicia nuevamente',
                tipo: 'timer',
                ocultaCancel: true
            };
            this.confSrv.confirmar(msj).subscribe(
                res => {
                    this.navegar('/login');
                },
                err => {
                    this.navegar('/login');
                }
            );
        });
        this.idle.onIdleStart.subscribe(() => { console.log('Idle Iniciado'); });
        this.idle.onTimeoutWarning.subscribe((countdown: any) => { console.log(`Conteo Inactivo... ${countdown}`); });

        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => { console.log('Idle Ping'); });
        this.idle.watch();
    }

    stop(): void {
        // console.log('IdlebootService::stop');
        this.idle.stop();
    }

    private navegar(ruta: string) {
        this.router.navigate([ruta]);
    }
}
