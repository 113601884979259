import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CargandoService, MensajesService, SessionService, ConfirmacionService } from 'src/app/shared';
import { AdministradoresService } from './../administradores.service';

@Component({
    selector: 'int-dlg-merge-administradores',
    templateUrl: './dlg-merge-admin.component.html',
    styleUrls: ['./dlg-merge-admin.component.css']
})
export class DlgMergeAdminComponent implements OnInit {

    adminSelected: any;
    adminForm: FormGroup;
    esNuevo = true;
    oculta = true;

    constructor(@Inject(MAT_DIALOG_DATA) public params: any,
                public dialogRef: MatDialogRef<DlgMergeAdminComponent>,
                private load: CargandoService,
                private msjSrv: MensajesService,
                private ssnSrv: SessionService,
                private confirmSrv: ConfirmacionService,
                private builder: FormBuilder,
                private dialog: MatDialog,
                private adminSrv: AdministradoresService) {
    }

    ngOnInit(): void {
        this.InitComponente();
    }


    private InitComponente(): void {
        this.adminSelected = this.params.adminSelected as any;
        this.esNuevo = this.params.esNuevo as boolean;
        this.creaForm();
        if (this.esNuevo) {
            this.adminForm.reset();
            this.adminForm.controls.updatePass.setValue(true);
            this.adminForm.controls.updatePass.disable();
            this.validaUpdatePass(true);
        } else {
            this.setForm(this.adminSelected);
            this.adminForm.controls.updatePass.enable();
            this.adminForm.controls.updatePass.setValue(false);
            this.validaUpdatePass(false);
        }
    }

    validaUpdatePass(event): void {
        if (this.adminForm.getRawValue().updatePass) {
            this.adminForm.controls.password.setValue(null);
            this.adminForm.controls.password.enable();
            this.adminForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
            this.adminForm.controls.password.updateValueAndValidity();
            this.adminForm.updateValueAndValidity();
        } else {
            this.adminForm.controls.password.setValue(null);
            this.adminForm.controls.password.disable();
            this.adminForm.controls.password.setValidators([]);
            this.adminForm.controls.password.updateValueAndValidity();
            this.adminForm.updateValueAndValidity();
        }
    }

    private creaForm(): void {
        this.adminForm = this.builder.group({
            id: [null, Validators.nullValidator],
            username: [null, Validators.required],
            password: [null, [Validators.required, Validators.minLength(6)]],
            nombre: [null, Validators.required],
            apellido: [null, Validators.required],
            correo: [null, [Validators.required, Validators.email]],
            nivel: [null, Validators.required],
            updatePass: [false, Validators.required],
        });
    }

    private setForm(sel: any): void {
        // console.log(JSON.stringify(sel));
        this.adminForm.controls.id.patchValue(sel.id);
        this.adminForm.controls.username.patchValue(sel.username);
        this.adminForm.controls.password.patchValue(sel.password);
        this.adminForm.controls.nombre.patchValue(sel.nombre);
        this.adminForm.controls.apellido.patchValue(sel.apellido);
        this.adminForm.controls.correo.patchValue(sel.correo);
        this.adminForm.controls.nivel.patchValue(sel.nivel);

        this.adminForm.controls.username.disable();
        this.adminForm.controls.username.updateValueAndValidity();

    }

    cerrarDialogo(): void {
        this.dialogRef.close();
    }

    mergeAdmin(): void {
        let msg = 'Se insertará el administrador, ¿Está seguro de que desea continuar?';
        if (!this.esNuevo) {
            msg = 'Se actualizará el administrador, ¿Está seguro de que desea continuar?';
        }
        this.confirmSrv.confirmarSimple(msg).subscribe(
            res => {
                if (res) {
                    this.load.toggle(true);
                    this.adminSrv.mergeAdministradores(this.adminForm.getRawValue(), this.esNuevo).subscribe(
                        resMerge => {
                            if (resMerge.code === 0) {
                                this.msjSrv.agregaInfo(resMerge.message);
                                this.load.toggle(false);
                                this.cerrarDialogo();
                            } else {
                                this.msjSrv.agregaError(resMerge.message);
                                this.load.toggle(false);
                            }
                        },
                        errMerge => {
                            console.error('mergeAdmin::Error al actualizar el admin', errMerge);
                            this.msjSrv.agregaError('Error al actualizar el administrador, intente más tarde.');
                            this.load.toggle(false);
                        }
                    );
                }
            }
        );
    }

}
