import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { MatDialog } from '@angular/material';
import { PDFVisorComponent } from '../pdf-visor';
import { DocumentosService } from '../especificaciones/documentos.service';


@Component({
    selector: 'int-comparativos',
    templateUrl: './comparativos.component.html',
    styleUrls: ['./comparativos.component.css']
})
export class ComparativosComponent implements OnInit {

    documentos: any[];

    constructor(private router: Router,
                private dialog: MatDialog,
                private config: PortalConfigService,
                private load: CargandoService,
                private docSrv: DocumentosService,
                private msjSrv: MensajesService) {
        config.app = 'Comparativos';
    }

    ngOnInit() {
        this.getDocumentos();
    }

    private getDocumentos(): void {
        this.documentos = [];
        this.load.toggle(true);
        this.docSrv.GetDocumentos('Comparativos').subscribe(
            resDoc => {
                this.documentos = resDoc;
                this.load.toggle(false);
            },
            errDoc => {
                console.error('Error al obtener los documentos...', errDoc);
                this.msjSrv.agregaError('Error al obtener los documentos, intente nuevamente.');
                this.load.toggle(false);
            }
        );
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    abrePDF(file: string, title: string): void {
        console.log('Abre: [' + file + '] [' + title + ']');
        this.load.toggle(true);
        this.docSrv.MuestraPDF(file).subscribe(
            resBytes => {
                if (resBytes) {
                    this.dialog.open(PDFVisorComponent, {
                        width: '90%',
                        closeOnNavigation: true,
                        disableClose: true,
                        hasBackdrop: true,
                        data: {
                            info: title,
                            esData: true,
                            nameFile: file.replace('/', '_'),
                            url: resBytes
                        }
                    });
                    this.load.toggle(false);
                } else {
                    this.msjSrv.agregaWarn('Archivo no disponible..');
                    this.load.toggle(false);
                }
            },
            errBytes => {
                console.error('Error al obtener el documento...', errBytes);
                this.msjSrv.agregaError('Error al obtener el archivo, intente nuevamente.');
                this.load.toggle(false);
            }
        );
    }
}
