import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeoutWith, map } from 'rxjs/operators';
import { StoreManagement } from 'src/app/shared';

@Injectable()
export class PreferenciasService {

    private relPath = {
        cambiaPasswordURL: '/services/preferencias/cambiaPassword.php',
        cambiaEmailURL: '/services/preferencias/cambiaEmail.php',
        sincronizarIntelisisURL: '/services/preferencias/sincronizarIntelisis.php'
    };

    constructor(@Inject('AUTH_API_ENDPOINT') private api: string,
                private http: HttpClient,
                private storeSrv: StoreManagement) { }

    cambiaPassword(isUser: number, passActual: string, passNew: string, esAdmin: boolean): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.cambiaPasswordURL}`;
        const param = {id: isUser, pwActual: passActual, pwNueva: passNew, isAdmin: esAdmin};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    cambiaEmail(idUser: number, email: string, esAdmin: boolean): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.cambiaEmailURL}`;
        const param = {id: idUser, correo: email, isAdmin: esAdmin};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    sincronizarIntelisis(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storeSrv.getToken()}`
            })
        };
        const url = `${this.api}${this.relPath.sincronizarIntelisisURL}`;
        const param = {};
        return this.http
            .post<any>(url, param, httpOptions)
            .pipe(
                map(response => response[0]),
                timeoutWith(1999999, throwError(new Error('Request timed out'))),
                catchError(this.handleError('login', {})),
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed:`, error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
