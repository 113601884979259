import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService, PortalConfigService, CargandoService } from 'src/app/shared';
import { ClientesService } from './clientes.service';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { SolicitudesService } from '../solicitudes';
import { DlgDetClienteComponent } from './dlg-merge-cliente';

@Component({
    selector: 'int-clientes-intercarton',
    templateUrl: './clientes.component.html',
    styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

    displayedColumns = ['idIntelisis', 'Usuario', 'Nombre', 'Company', 'Estatus'];
    clientes: any[] = [];
    agentes: any[] = [];

    dataSource = new MatTableDataSource<any>();
    clienteSelected: any = null;
    regActivo = false;

    @ViewChild(MatPaginator, null) paginator: MatPaginator;

    constructor(private router: Router,
                private config: PortalConfigService,
                private cteSrv: ClientesService,
                private load: CargandoService,
                private dialog: MatDialog,
                private solSrv: SolicitudesService,
                private msjSrv: MensajesService) {
        this.config.app = 'Clientes del Sistema';
    }

    ngOnInit() {
        this.clienteSelected = null;
        this.getCientes();
    }

    private getCientes(): void {
        this.load.toggle(true);
        this.cteSrv.getClientes().subscribe(
            resCte => {
                this.clientes = resCte;
                this.dataSource = new MatTableDataSource<any>(this.clientes);
                this.dataSource.paginator = this.paginator;
                this.regActivo = false;
                this.load.toggle(false);
            },
            errCte => {
                this.load.toggle(false);
                console.error('Error al consultar los clientes::: ', errCte);
                this.msjSrv.agregaError('Error al obtener los clientes, intente más tarde.');
            }
        );
    }

    seleccionaElemento(elementSelected: any): void {
        this.clienteSelected = elementSelected;
        this.regActivo = true;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    applyFilterIdIntelisis(filterValue: string) {
        if (filterValue !== null && filterValue !== '' && filterValue !== undefined) {
            const cteTemp: any[] = [];
            this.clientes.forEach(item => {
                if (filterValue !== '-1') {
                    if (item.id_intelisis) {
                        if (item.id_intelisis.startsWith(filterValue)) {
                            cteTemp.push(item);
                        }
                    }
                } else {
                    if (item.id_intelisis === null) {
                        cteTemp.push(item);
                    }
                }
            });
            this.dataSource = new MatTableDataSource<any>(cteTemp);
            this.dataSource.paginator = this.paginator;
        } else {
            this.dataSource = new MatTableDataSource<any>(this.clientes);
            this.dataSource.paginator = this.paginator;
        }
    }

    navegar(ruta: string) {
        this.router.navigate([ruta]);
    }

    nuevo(): void {
        const dialogRef = this.dialog.open(DlgDetClienteComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                isEdit: false,
                clienteSelected: null,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

    irEditar(): void {
        const dialogRef = this.dialog.open(DlgDetClienteComponent, {
            width: '80%',
            closeOnNavigation: true,
            disableClose: true,
            hasBackdrop: true,
            data: {
                isEdit: true,
                clienteSelected: this.clienteSelected,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            this.seleccionaElemento(null);
        });
    }

}
