import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  Auth2Service, CargandoService,
  IdlebootService, SessionService, PortalConfigService,
  DataService, MensajesService, StoreManagement
} from './../shared';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ConfirmacionService } from './../shared/components/dialogo-confirmacion/confirmacion.service';
import { interval, Subscription } from 'rxjs';
import { NotificacionesService, MenuAppService,
  InventariosApartadosService, SelectorUsuariosComponent,
  PortalToHomeService, HomeToPortalService } from './componentes';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'int-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit, OnDestroy {
  usuario: string;
  compania: string;
  // nivelUser: number;
  nombreApp: string;
  darkTheme = false;
  isAdmin: boolean;
  timeNoti = interval((5 * 60 * 1000));
  subscription: Subscription;
  numNoti: number;

  usuarioPerfil: any;
  username: string;
  muestraPerfil: boolean;
  apps: any[];
  muestraApartado: boolean;
  idIntelisis: any;
  isVendedor: boolean;

  subscriptionMenu: Subscription;

  constructor(private title: Title,
              private router: Router,
              private element: ElementRef,
              private overlayContainer: OverlayContainer,
              private invAprSrv: InventariosApartadosService,
              private authSrv: Auth2Service,
              private loadSrv: CargandoService,
              private msjSrv: MensajesService,
              private idle: IdlebootService,
              private ssnSrv: SessionService,
              private config: PortalConfigService,
              private dlgSrv: ConfirmacionService,
              private paramSrv: DataService,
              private ssnStora: StoreManagement,
              private dialog: MatDialog,
              private menuSrv: MenuAppService,
              private notiSrv: NotificacionesService,
              private portalToHomeSrv: PortalToHomeService,
              private homeToPortalSrv: HomeToPortalService,
  ) {
    config.isDarkTheme$.subscribe(isDarktheme => this.setTheme(isDarktheme));
    config.appName$.subscribe(appName => this.setNombreapp(appName));
    config.app = 'Inicio';
    config.isDark = false;
    this.usuario = (this.ssnSrv.principal.nombre === null ? '' : this.ssnSrv.principal.nombre) + ' ' +
      (this.ssnSrv.principal.apellido === null ? '' : this.ssnSrv.principal.apellido);
    this.compania = this.ssnSrv.principal.compania === null ? '' : this.ssnSrv.principal.compania;
    this.isAdmin = this.ssnSrv.principal.isAdmin;
    this.ssnSrv.principal.numNotificaciones = 0;
    this.isVendedor = (this.ssnSrv.principal.bVendedor === 'V' ? true : false);
    this.numNoti = 0;
    // this.idle.start(1800); // Produccion
    // this.idle.start(30); // Desarrollo

    // Notificaciones
    if (!this.ssnSrv.principal.isAdmin) {
      this.getNotificaciones();
      this.subscription = this.timeNoti.subscribe(() => this.getNotificaciones());
      this.router.events.subscribe((changeRoute) => {
        if (changeRoute instanceof NavigationEnd) {
          this.getNotificaciones();
        }
      });
    }

    // Se subscribe al listener del home
    this.subscriptionMenu = this.homeToPortalSrv.Check().subscribe(
      value => {
        // console.log('PortalComponent::Se ha realizo cambio en el menu del home::: ', value);
        this.getUsuarioPerfil();
        this.getInvEspecial();
      }
    );
  }

  ngOnInit(): void {
    this.getInvEspecial();
  }

  ngOnDestroy() {
    this.subscriptionMenu.unsubscribe();
  }

  cerrarSesion() {

    let msj = '¿Desea cerrar sesión?';
    if (this.numNoti > 0) {
      msj = 'Tiene pedidos por enviar. ¿Desea cerrar sesión?';
    }

    this.dlgSrv.confirmarSimple(msj).subscribe(
      res => {
        if (res) {
          this.ssnStora.removePerfilUsuario();
          if (this.ssnSrv.principal.device === 'desktop') {
            if (this.ssnSrv.principal.isAdmin) {
              this.loadSrv.toggle(true);
              this.idle.stop();
              this.authSrv.logout();
              this.loadSrv.toggle(false);
              this.navegar('/login-admin');
            } else {
              this.loadSrv.toggle(true);
              this.idle.stop();
              this.authSrv.logout();
              this.loadSrv.toggle(false);
              this.navegar('/login');
            }
          } else {
            this.loadSrv.toggle(true);
            this.idle.stop();
            this.authSrv.logout();
            this.loadSrv.toggle(false);
            // window.location.reload();
            this.paramSrv.sessionMap.Realod = true;
            this.navegar('/logout-mobile');
          }
        }
      });
  }

  toggleTheme() {
    this.config.isDark = !this.darkTheme;
  }

  setTheme(isDark: boolean) {
    this.darkTheme = isDark;
    const darkThemeClass = 'int-dark-theme';
    if (this.darkTheme) {
      this.element.nativeElement.classList.add(darkThemeClass);
      this.overlayContainer.getContainerElement().classList.add(darkThemeClass);
    } else {
      this.element.nativeElement.classList.remove(darkThemeClass);
      this.overlayContainer.getContainerElement().classList.remove(darkThemeClass);
    }
  }

  setNombreapp(nombre: string) {
    this.nombreApp = nombre;
    this.title.setTitle(`Inventarios | ${nombre}`);
  }

  navegar(ruta: string) {
    this.router.navigate([ruta]);
  }

  irPreferenciasUser() {
    this.navegar('/preferencias');
  }

  irInicio(): void {
    this.navegar('/');
  }

  private getNotificaciones(): void {
    if (this.ssnSrv.principal !== null) {
      this.getUsuarioPerfil();
      this.notiSrv.getNumNotificaciones(this.username).subscribe(
        resNoti => {
          this.ssnSrv.principal.numNotificaciones = resNoti[0].Cuantos;
          this.numNoti = this.ssnSrv.principal.numNotificaciones;
        },
        errNoti => {
          console.error('Error al consultar las notificaciones....', errNoti);
        }
      );
    }
  }

  private getUsuarioPerfil() {
    this.muestraPerfil = false;
    this.muestraApartado = false;
    this.usuarioPerfil = this.ssnStora.getPerfilUsuario();
    if (this.usuarioPerfil !== 'undefined') {
      this.muestraPerfil = true;
      this.usuarioPerfil = JSON.parse(this.usuarioPerfil);
      this.idIntelisis = this.usuarioPerfil.id_intelisis;
      this.username = this.usuarioPerfil.username;
    } else {
      this.idIntelisis = this.ssnSrv.principal.idIntelisis;
      this.username = this.ssnSrv.principal.username;
    }
  }

  private getInvEspecial(): void {
    this.loadSrv.toggle(true);
    this.invAprSrv.TieneInventarioEspecial(this.idIntelisis).subscribe(
      resInv => {
        if (resInv.Cuantos > 0) {
          this.muestraApartado = true;
        } else {
          this.muestraApartado = false;
        }
        this.ReconstruyeMenu();
      },
      errInv => {
        console.error('Error al obtener si aplica inventario especial:: ', errInv);
        this.muestraApartado = false;
        this.ReconstruyeMenu();
      }
    );
  }

  private ReconstruyeMenu(): void {
    this.loadSrv.toggle(true);
    this.apps = [];
    const EsAdmin: string = (this.isAdmin ? 'V' : 'F');
    this.menuSrv.GetMenu(EsAdmin, 'F').subscribe(
      resMenu => {
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < resMenu.length; index++) {
          const item = resMenu[index];
          if (item.Clave === 'InvApartado') {
            if (this.muestraApartado) {
              this.apps.push(item);
            }
          } else {
            if (item.Clave === 'Perfil') {
              if (this.isVendedor) {
                this.apps.push(item);
              }
            } else {
              this.apps.push(item);
            }
          }
        }
        this.loadSrv.toggle(false);
      },
      errMenu => {
        console.error('Error al generar le menu...', errMenu);
        this.loadSrv.toggle(false);
      },
    );
  }

  irA(ruta: string) {
    if (ruta && String(ruta).length) {
      if (ruta === '0') {
        this.openSelectorUsuario();
      } else {
        this.navegar(ruta);
      }
    } else {
      this.msjSrv.agregaWarn('Ruta de aplicación no configurada');
    }
  }

  openSelectorUsuario(): void {
    this.dialog.open(SelectorUsuariosComponent, {
      width: '55%',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data: { }
    }).afterClosed().subscribe(resClose => {
      if (resClose) {
        this.getUsuarioPerfil();
        this.getInvEspecial();
        this.getNotificaciones();
        this.portalToHomeSrv.Poll();
      }
    });
  }

}
