import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CargandoService } from '../dialogo-carga/cargando.service';
// import { Auth2Service, IdlebootService } from '../../submodules';


@Component({
    selector: 'int-logout-button',
    template: `
    <button mat-button (click)="cerrarSesion()" matTooltip="Cerrar sesión" matTooltipPosition="before">
      <mat-icon class="md-36">exit_to_app</mat-icon>
    </button>
  `,
    styles: []
})
export class LogoutButtonComponent {

    constructor(private router: Router,
                private loadSrv: CargandoService,
        // private authSrv: Auth2Service,
        // private idle: IdlebootService
        ) {
    }

    cerrarSesion() {
        this.loadSrv.toggle(true);
        // this._idle.stop();
        // this._authSrv.logout();
        this.loadSrv.toggle(false);
        window.location.reload();
    }

}
